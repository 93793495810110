import { RapidApiPlugin, RapidApiPluginReq } from "../model/rapid_api_plugin";
import { customFetch } from "../utils/http";
import { PaginationReq } from "../objects/pagination";

export const createRapidApiPlugin = async (
  rapidApiPlugin: RapidApiPluginReq
) => {
  return await customFetch(`rapidApiPlugin`, {
    method: "POST",
    body: JSON.stringify(rapidApiPlugin),
  });
};

export const getRapidApiPlugins = async (pagination: PaginationReq) => {
  const params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
  };
  if (pagination.search && pagination.search != "") {
    params["search"] = pagination.search;
  }
  return await customFetch(`rapidApiPlugin?${new URLSearchParams(params)}`);
};
export const getRapidApiPlugin = async (id: string) => {
  return await customFetch(`rapidApiPlugin/${id}`);
};

export const updateRapidApiPlugin = async (
  id: string,
  rapidApiPlugin: RapidApiPluginReq
) => {
  return await customFetch(`rapidApiPlugin/${id}`, {
    method: "PUT",
    body: JSON.stringify(rapidApiPlugin),
  });
};

export const deleteRapidApiPlugin = async (id: string): Promise<void> => {
  await customFetch(`rapidApiPlugin/${id}`, {
    method: "DELETE",
  });
};


