import { useContext, useEffect, useState, type FC } from "react";
import { Board } from "../model/board";
import {
  PiBell,
  PiCalendar,
  PiCalendarDots,
  PiPassword,
  PiPlusCircleBold,
  PiRadioButtonFill,
  PiTrash,
} from "react-icons/pi";
import InlineForm from "./InlineForm";
import { Divider, Dropdown, Toggle } from "rsuite";
import {
  FormField,
  FormFieldOption,
  FormFieldType,
  FormSection,
} from "../model/form";
import {
  RiDropdownList,
  RiFileUploadLine,
  RiText,
  RiTextBlock,
} from "react-icons/ri";
import { TbCheckbox, TbNumber123 } from "react-icons/tb";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { RxSwitch } from "react-icons/rx";
import { BiMoneyWithdraw } from "react-icons/bi";
import { randomStr } from "../utils/helper";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { HiXMark } from "react-icons/hi2";
import { StrictModeDroppable } from "./StrictModeDroppable";
import { BsPuzzle } from "react-icons/bs";
import { CompanyContext } from "../objects/company_context";
import { getCompany } from "../repositories/company";

interface BoardSettingProps {
  board: Board;
  onSave: (val: Board, showToast?: boolean) => void;
}

const BoardSetting: FC<BoardSettingProps> = ({ board, onSave }) => {
  const [temp, setTemp] = useState<Board | null>(null);
  const [customFields, setCustomFields] = useState<FormField[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const { company, setCompany } = useContext(CompanyContext);
  const [rapidApiSummaries, setRapidApiSummaries] = useState<string[]>([]);

  useEffect(() => {
    setTemp({
      ...board!,
    });
    setCustomFields(board.custom_fields ?? []);
    setRapidApiSummaries(board.rapid_api_plugin_summaries ?? []);
  }, [board]);

  useEffect(() => {
    if (company) {
    }
  }, [company]);

  const renderIcon = (e: FormFieldType, size: number) => {
    switch (e) {
      case FormFieldType.TextField:
        return <RiText size={size} />;
      case FormFieldType.TextArea:
        return <RiTextBlock size={size} />;
      case FormFieldType.RadioButton:
        return <PiRadioButtonFill size={size} />;
      case FormFieldType.Checkbox:
        return <TbCheckbox size={size} />;
      case FormFieldType.DatePicker:
        return <PiCalendar size={size} />;
      case FormFieldType.DateRangePicker:
        return <PiCalendarDots size={size} />;
      case FormFieldType.NumberField:
        return <TbNumber123 size={size} />;
      case FormFieldType.EmailField:
        return <MdOutlineAlternateEmail size={size} />;
      case FormFieldType.PasswordField:
        return <PiPassword size={size} />;
      case FormFieldType.FileUpload:
        return <RiFileUploadLine size={size} />;
      case FormFieldType.ToggleSwitch:
        return <RxSwitch size={size} />;
      case FormFieldType.Dropdown:
        return <RiDropdownList size={size} />;
      case FormFieldType.Currency:
        return <BiMoneyWithdraw size={size} />;
    }

    return null;
  };

  const addField = (formFieldType: FormFieldType) => {
    let options: FormFieldOption[] = [];
    if (
      formFieldType == FormFieldType.Checkbox ||
      formFieldType == FormFieldType.RadioButton ||
      formFieldType == FormFieldType.Dropdown
    ) {
      options = [
        {
          label: "Label",
          value: "Value",
        },
      ];
    }
    let newData = [
      ...customFields,
      {
        id: randomStr(20),
        label: "New Field",
        type: formFieldType,
        options: options,
        required: false,
        is_multi:
          formFieldType == FormFieldType.Checkbox ||
          formFieldType == FormFieldType.DateRangePicker
            ? true
            : false,
        placeholder: "",
        default_value: "",
        help_text: "",
        disabled: false,
      },
    ];
    setCustomFields(newData);
    
    onSave?.({
      ...board!,
      custom_fields: newData,
      rapid_api_plugin_summaries: rapidApiSummaries,
    });
  };

  const addFieldMenu = () => (
    <div className="  cursor-pointer mx-2">
      <Dropdown
        className="custom-menu"
        title={
          <PiPlusCircleBold
            className=" text-gray-600 hover:text-green-400"
            size={20}
          />
        }
        noCaret
        placement="bottomEnd"
        trigger={"click"}
      >
        <Dropdown.Item onClick={() => addField(FormFieldType.TextField)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.TextField, 12)} Text
          </div>
        </Dropdown.Item>
        {/* <Dropdown.Item onClick={() => addField(FormFieldType.TextArea)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.TextArea, 12)}
            Text Area
          </div>
        </Dropdown.Item> */}
        <Dropdown.Item onClick={() => addField(FormFieldType.RadioButton)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.RadioButton, 12)}
            Radio Button
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => addField(FormFieldType.Checkbox)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.Checkbox, 12)}
            Checkbox
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => addField(FormFieldType.Dropdown)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.Dropdown, 12)}
            Dropdown
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => addField(FormFieldType.DatePicker)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.DatePicker, 12)}
            Date Picker
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => addField(FormFieldType.DateRangePicker)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.DateRangePicker, 12)}
            Date Range Picker
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => addField(FormFieldType.NumberField)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.NumberField, 12)}
            Number Picker
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => addField(FormFieldType.Currency)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.Currency, 12)}
            Currency
          </div>
        </Dropdown.Item>
        {/* <Dropdown.Item onClick={() => addField(FormFieldType.EmailField)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.EmailField, 12)}
            Email Field
          </div>
        </Dropdown.Item> */}
        {/* <Dropdown.Item onClick={() => addField(FormFieldType.PasswordField)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.PasswordField, 12)}
            Password Field
          </div>
        </Dropdown.Item> */}
        {/* <Dropdown.Item onClick={() => addField(FormFieldType.FileUpload)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.FileUpload, 12)}
            File Upload
          </div>
        </Dropdown.Item> */}
        <Dropdown.Item onClick={() => addField(FormFieldType.ToggleSwitch)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.ToggleSwitch, 12)}
            Toggle Switch
          </div>
        </Dropdown.Item>
      </Dropdown>
    </div>
  );

  const updateOption = (e: FormField, index: number, key: string, val: any) => {
    let newData = [
      ...customFields.map((f) => {
        if (f.id === e.id) {
          return {
            ...f,
            options: f.options.map((o, i) => {
              if (i === index) {
                return { ...o, [key]: val };
              }
              return o;
            }),
          };
        }
        return f;
      }),
    ];
    setCustomFields(newData);
  };

  const addOption = (e: FormField) => {
    let newData = [
      ...customFields.map((f) => {
        if (f.id === e.id) {
          return {
            ...f,
            options: [...f.options, { label: "Label", value: "Value" }],
          };
        }
        return f;
      }),
    ];
    setCustomFields(newData);
    onSave?.({
      ...board!,
      custom_fields: newData,
    });
  };

  const removeOption = (e: FormField, i: number) => {
    let newData = [
      ...customFields.map((f) => {
        if (f.id === e.id) {
          return {
            ...f,
            options: f.options.filter((o, j) => i !== j),
          };
        }
        return f;
      }),
    ];
    setCustomFields(newData);
    onSave?.({
      ...board!,
      custom_fields: newData,
    });
  };

  const renderOption = (e: FormField) => {
    if (
      e.type == FormFieldType.Checkbox ||
      e.type == FormFieldType.RadioButton ||
      e.type == FormFieldType.Dropdown
    )
      return (
        <div className="mb-4">
          {e.options.map((o, i) => (
            <div
              className=" justify-between flex flex-row items-center mb-2"
              key={`${e.id}-${i}`}
            >
              <div className="grid grid-cols-2 gap-2 flex-1">
                <input
                  value={o.label}
                  className="px-2 py-1 text-sm rounded-lg border bg-transparent"
                  placeholder="Label"
                  onChange={(val) => {
                    updateOption(e, i, "label", val.target.value);
                  }}
                  onBlur={() => {
                    onSave?.({
                      ...board!,
                      custom_fields: customFields,
                    });
                  }}
                />
                <input
                  value={o.value}
                  className="px-2 py-1 text-sm rounded-lg border bg-transparent"
                  placeholder="Value"
                  onChange={(val) => {
                    updateOption(e, i, "value", val.target.value);
                  }}
                  onBlur={() => {
                    onSave?.({
                      ...board!,
                      custom_fields: customFields,
                    });
                  }}
                />
              </div>
              <HiXMark
                className="w-4 h-4 cursor-pointer  hover:text-red-400"
                onClick={() => removeOption(e, i)}
              />
            </div>
          ))}
          <p
            className="text-sm hover:font-[500] cursor-pointer"
            onClick={() => addOption(e)}
          >
            + Option
          </p>
        </div>
      );
    return null;
  };

  const updateField = (e: FormField, field: string, val: any) => {
    setCustomFields([
      ...customFields.map((f) => {
        if (f.id === e.id) {
          return { ...f, [field]: val };
        }
        return f;
      }),
    ]);
  };

  const renderField = (e: FormField, index: number) => {
    return (
      <Draggable draggableId={e.id} index={index} key={e.id}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`group/item  bg-gray-50 rounded-lg p-4 hover:border ${
              snapshot.isDragging ? "dragging" : ""
            } mb-2`}
          >
            <div className="flex flex-row gap-2 ">
              <div className="py-1">{renderIcon(e.type, 24)}</div>
              <div className="flex flex-col flex-1">
                <input
                  disabled={isDisabled}
                  onChange={(val) => {
                    updateField(e, "label", val.target.value);
                  }}
                  value={e.label ?? ""}
                  className="focus:ring-0 focus:outline-none focus:ring-gray-200  text-lg w-full bg-transparent"
                  onBlur={() => {
                    onSave?.({
                      ...board!,
                      custom_fields: customFields,
                    });
                  }}
                ></input>

                {renderOption(e)}

                {/* {e.type == FormFieldType.Dropdown && (
                    <Toggle
                      checked={e.is_multi}
                      onChange={(val) => {
                        updateField( e, "is_multi", val);
                      }}
                      onBlur={() => {
                        saveForm({
                          ...form!,
                          sections,
                        });
                      }}
                    >
                      Multiple Choice
                    </Toggle>
                  )} */}
              </div>
              <div className="py-2">
                <PiTrash
                  className="cursor-pointer group/edit invisible  group-hover/item:visible mr-2 hover:text-red-400 "
                  onClick={() => {
                    setCustomFields([
                      ...customFields.filter((f) => f.id != e.id),
                    ]);
                    onSave?.({
                      ...board!,
                      custom_fields: [
                        ...customFields.filter((f) => f.id != e.id),
                      ],
                    });
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (isDisabled) return;
    const { source, destination, type } = result;

    if (!destination) return;
    if (type === "ITEM") {
      const newColumns = [...customFields];
      const [movedColumn] = newColumns.splice(source.index, 1);
      newColumns.splice(destination.index, 0, movedColumn);
      setCustomFields(newColumns);
      onSave?.({
        ...board!,
        custom_fields: newColumns,
      });
    }
  };

  return (
    <div className="p-6 h-full overflow-y-auto">
      <div className="grid grid-cols-2 gap-4">
        <div className="rounded-lg border p-6 hover:shadow">
          <h1 className="text-2xl font-[500] flex flex-row gap-2 items-center mb-4">
            <PiBell />
            Notification
          </h1>
          <div className="pl-8">
            <div style={{ marginBottom: 40 }}>
              <h3 className="text-lg">Email Notification</h3>
              <Divider
                className=""
                style={{ marginTop: 5, marginBottom: 10 }}
              />
              <InlineForm
                width={300}
                paddingY={10}
                label="Board Email Notification"
              >
                <Toggle
                  checked={temp?.board_email_notification}
                  onChange={(checked) => {
                    setTemp({ ...temp!, board_email_notification: checked });
                  }}
                />
              </InlineForm>
              <InlineForm
                width={300}
                paddingY={10}
                label="Comment Email Notification"
              >
                <Toggle
                  checked={temp?.comment_email_notification}
                  onChange={(checked) => {
                    setTemp({ ...temp!, comment_email_notification: checked });
                  }}
                />
              </InlineForm>
              <InlineForm
                width={300}
                paddingY={10}
                label="Assign Task Email Notification"
              >
                <Toggle
                  checked={temp?.assign_task_email_notification}
                  onChange={(checked) => {
                    setTemp({
                      ...temp!,
                      assign_task_email_notification: checked,
                    });
                  }}
                />
              </InlineForm>
              <InlineForm
                width={300}
                paddingY={10}
                label="Change Task Status Email Notification"
              >
                <Toggle
                  checked={temp?.change_task_status_email_notification}
                  onChange={(checked) => {
                    setTemp({
                      ...temp!,
                      change_task_status_email_notification: checked,
                    });
                  }}
                />
              </InlineForm>
            </div>

            <div style={{ marginBottom: 40 }}>
              <h3 className="text-lg mt-4">Push Notification</h3>
              <Divider
                className=""
                style={{ marginTop: 5, marginBottom: 10 }}
              />
              <InlineForm
                width={300}
                paddingY={10}
                label="Board Push Notification"
              >
                <Toggle
                  checked={temp?.board_push_notification}
                  onChange={(checked) => {
                    setTemp({ ...temp!, board_push_notification: checked });
                  }}
                />
              </InlineForm>
              <InlineForm
                width={300}
                paddingY={10}
                label="Comment Push Notification"
              >
                <Toggle
                  checked={temp?.comment_push_notification}
                  onChange={(checked) => {
                    setTemp({ ...temp!, comment_push_notification: checked });
                  }}
                />
              </InlineForm>
              <InlineForm
                width={300}
                paddingY={10}
                label="Assign Task Push Notification"
              >
                <Toggle
                  checked={temp?.assign_task_push_notification}
                  onChange={(checked) => {
                    setTemp({
                      ...temp!,
                      assign_task_push_notification: checked,
                    });
                  }}
                />
              </InlineForm>
              <InlineForm
                width={300}
                paddingY={10}
                label="Change Task Status Push Notification"
              >
                <Toggle
                  checked={temp?.change_task_status_push_notification}
                  onChange={(checked) => {
                    setTemp({
                      ...temp!,
                      change_task_status_push_notification: checked,
                    });
                  }}
                />
              </InlineForm>
            </div>

            {/* <h3 className="text-lg mt-4">SMS Notification</h3>
        <Divider className="" style={{ marginTop: 5, marginBottom: 10 }} />
        <InlineForm width={300} paddingY={10} label="Board SMS Notification">
          <Toggle
            checked={temp?.board_sms_notification}
            onChange={(checked) => {
              setTemp({ ...temp!, board_sms_notification: checked });
            }}
          />
        </InlineForm>
        <InlineForm
          width={300}
          paddingY={10}
          label="Comment SMS Notification"
        >
          <Toggle
            checked={temp?.comment_sms_notification}
            onChange={(checked) => {
              setTemp({ ...temp!, comment_sms_notification: checked });
            }}
          />
        </InlineForm>
        <InlineForm
          width={300}
          paddingY={10}
          label="Assign Task SMS Notification"
        >
          <Toggle
            checked={temp?.assign_task_sms_notification}
            onChange={(checked) => {
              setTemp({ ...temp!, assign_task_sms_notification: checked });
            }}
          />
        </InlineForm>
        <InlineForm
          width={300}
          paddingY={10}
          label="Change Task Status SMS Notification"
        >
          <Toggle
            checked={temp?.change_task_status_sms_notification}
            onChange={(checked) => {
              setTemp({
                ...temp!,
                change_task_status_sms_notification: checked,
              });
            }}
          />
        </InlineForm> */}
            <div className="flex justify-end mt-4">
              <button
                className="bg-sky-500 hover:bg-sky-700 w-36 text-white font-bold py-2 px-4 rounded"
                onClick={() => {
                  if (temp) {
                    // todo: save board
                    onSave(temp, true);
                  }
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="rounded-lg border p-6 hover:shadow mb-4">
            <div className="flex flex-row justify-between items-center">
              <h1 className="text-2xl font-[500] flex flex-row gap-2 items-center mb-4">
                <PiBell />
                Custom Fields
              </h1>
              {addFieldMenu()}
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <StrictModeDroppable droppableId={board.id} type="ITEM">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="task-list min-h-[32px] h-full] px-4 overflow-y-auto"
                  >
                    {customFields.map((f, i) => (
                      <div className="" key={f.id}>
                        {renderField(f, i)}
                      </div>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            </DragDropContext>
          </div>
          <div className="rounded-lg border p-6 hover:shadow">
            <div className="flex flex-row justify-between items-center">
              <h1 className="text-2xl font-[500] flex flex-row gap-2 items-center mb-4">
                <BsPuzzle />
                Plugin Summary
              </h1>
            </div>
            {(company?.rapid_api_plugins ?? [])
              .filter((e) => e.is_active)
              .map((e) => (
                <div className="flex flex-col mb-4" key={e.id}>
                  <h1 className="text-lg font-[500] flex flex-row gap-2 items-center">
                    {e.name}
                  </h1>
                  <ul>
                    {e.endpoints.map((ep) => (
                      <li key={ep.id} className="flex flex-row justify-between">
                        <span>{ep.title}</span>
                        <Toggle
                          checked={rapidApiSummaries.includes(ep.id)}
                          onChange={(checked) => {
                            if (checked) {
                              setRapidApiSummaries([
                                ...rapidApiSummaries,
                                ep.id,
                              ]);
                            } else {
                              setRapidApiSummaries(
                                rapidApiSummaries.filter((s) => s != ep.id)
                              );
                            }
                          }}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            <div className="flex justify-end mt-4">
              <button
                className="bg-sky-500 hover:bg-sky-700 w-36 text-white font-bold py-2 px-4 rounded"
                onClick={() => {
                  if (temp) {
                    // todo: save board
                    temp.rapid_api_plugin_summaries = rapidApiSummaries
                    onSave(temp, true);
                  }
                }}
              >
                Save 
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BoardSetting;
