import { useEffect, useState, type FC } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { login, resetPassword } from "../repositories/auth";
import { errorToast, successToast } from "../utils/helper-ui";
import { asyncLocalStorage } from "../utils/storage";
import { COMPANY, COMPANY_ID, TOKEN } from "../objects/constants";
import { Button, Input, Modal } from "rsuite";
import InlineForm from "../components/InlineForm";

interface LoginProps {}

declare global {
  interface Window {
    renderJarayaLoginButton: (config: {
      baseUrl: string;
      appName: string;
      callbackUrl: string;
    }) => void;
  }
}

const Login: FC<LoginProps> = ({}) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const nav = useNavigate();
  const [mounted, setMounted] = useState(false);
  const [modalForgot, setModalForgot] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    // Fungsi untuk memuat script eksternal
    const loadScript = () => {
      const script = document.createElement("script");
      script.src = `${process.env.REACT_APP_JARAYA_URL}/assets/js/jaraya-auth-react.js`; // Ganti dengan URL CDN sebenarnya
      script.async = true;
      script.onload = () => {
        if (window.renderJarayaLoginButton) {
          window.renderJarayaLoginButton({
            baseUrl: process.env.REACT_APP_JARAYA_SSO!,
            appName: "ARAYA",
            callbackUrl: `${process.env.REACT_APP_BASE_URL}/auth/handler`,
          });
        }
      };
      document.body.appendChild(script);
    };

    loadScript(); // Panggil fungsi untuk memuat script

    // Cleanup script saat komponen di-unmount
    return () => {
      const existingScript = document.querySelector(
        `script[src="${process.env.REACT_APP_JARAYA_URL}/assets/js/jaraya-auth-react.js"]`
      );
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, [mounted]);

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const res = await login({ password, email });
      if (!res.ok) {
        throw "Login Gagal";
      }
      const resJson = await res.json();
      // if (resJson.companies) {
      //   if (resJson.companies.length > 0) {
      //     const company = resJson.companies[0];
      //     asyncLocalStorage.setItem(COMPANY_ID, company.id);
      //     asyncLocalStorage.setItem(COMPANY, company);
      //   }
      // }
      await asyncLocalStorage.setItem(TOKEN, resJson.token);
      window.location.href = "/";
    } catch (e) {
      console.log(e);
      errorToast("Login Gagal");
    } finally {
      setIsLoading(false);
    }
  };

  const Loading = () => (
    <div className="flex items-center justify-center">
      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-orange-600"></div>
    </div>
  );

  let renderLogin = () => (
    <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0  ">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <div>
          <h1 className="text-xl font-bold mb-0 leading-tight tracking-tight text-gray-900 md:text-2xl ">
            Selamat Datang di Agensia
          </h1>
          <p className="m-0">Silakan Login dengan akun email anda</p>
        </div>
        <div className="space-y-4 md:space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
              placeholder="name@company.com"
              required={true}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                required={true}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    if (password.length > 0) {
                      handleLogin();
                    }
                  }
                }}
              />
              <AiOutlineEye
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                className={`w-5 ${
                  showPassword ? " text-gray-400" : " text-gray-600"
                } hover:text-gray-800 absolute right-2 top-3`}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-start"></div>
            <div
              className="text-sm font-medium text-primary-600 hover:underline "
              onClick={() => {
                setModalForgot(true);
              }}
            >
              Lupa password?
            </div>
          </div>
          {isLoading ? (
            Loading()
          ) : (
            <div>
              <button
                onClick={handleLogin}
                className="w-full text-white bg-orange-600 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
              >
                Sign in
              </button>
              <div id="jaraya-login-button"></div>
            </div>
          )}

          {/* <div className="flex justify-center">
    
                    <GoogleLogin
                        onSuccess={onSuccessGoogle}
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />
    
                </div> */}
          <div className="flex justify-center">
            <p>
              Belum punya akun?{" "}
              <Link
                className=" text-pink-600 hover:font-bold hover:text-pink-600"
                to="/register"
              >
                Daftar Sekarang
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 bg-gradient-to-tr from-violet-900 to-purple-600">
      <img src={"/images/logo.png"} className="w-16 py-8" />
      {renderLogin()}
      <Modal open={modalForgot} onClose={() => setModalForgot(false)}>
        <Modal.Header>Lupa Password</Modal.Header>
        <Modal.Body>
          <InlineForm label="Email">
            <Input
              type="email"
              name="email"
              placeholder="Masukkan Email"
              value={email}
              onChange={(e) => setEmail(e)}
              className="w-full"
            />
          </InlineForm>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setIsLoading(true);
              resetPassword(email)
                .then(() => {
                  setModalForgot(false);
                  successToast("Berhasil mengirim email reset password");
                })
                .catch(errorToast)
                .finally(() => {
                  setIsLoading(false);
                });
            }}
            appearance="primary"
          >
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Login;
