import { customFetch } from "../utils/http";
import { LoginReq, RegisterReq } from "../objects/auth_object";

export const login = async (req: LoginReq) => {
  return await customFetch("login", {
    method: "POST",
    body: JSON.stringify(req),
  });
};
export const register = async (req: RegisterReq) => {
  return await customFetch("register", {
    method: "POST",
    body: JSON.stringify(req),
  });
};
export const resetPassword = async (email: string) => {
  return await customFetch("reset-password", {
    method: "POST",
    body: JSON.stringify({email}),
  });
};
export const verification = async (token: string) => {
  return await customFetch(`verification/${token}`, {
    method: "POST",
  });
};


export const authWithJarayaAuthCode = async (authorization_code: string) => {
  return await customFetch("loginWithJaraya", {
    method: "POST",
    body: JSON.stringify({authorization_code}),
  });
};