import { useContext, useEffect, useState, type FC } from "react";
import { Company } from "../model/company";
import { errorToast, toolTip } from "../utils/helper-ui";
import { Button, Input, Modal } from "rsuite";
import { promptAssistant } from "../repositories/general";
import { LoadingContext } from "../objects/loading";

interface AiButtonProps {
  company?: Company | null;
  onSave: (val: string) => void;
  size?: number;
  skipReview?: boolean;
}

const AiButton: FC<AiButtonProps> = ({
  company,
  onSave,
  size = 6,
  skipReview = false,
}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [instruction, setInstruction] = useState("");
  const [result, setResult] = useState("");

  useEffect(() => {
    if (company) {
      setInstruction(company?.gemini_system_instruction ?? "");
    }
  }, [company]);
  return (
    <>
      {company?.gemini_api_key && !company?.is_client &&
        toolTip(
          "Gunakan Ai untuk membantu anda membuatkan deskripsi lebih baik",
          <img
            src="/images/gpt.png"
            alt=""
            className={`w-${size} h-${size} cursor-pointer`}
            onClick={() => setModalOpen(true)}
          />,
          "leftEnd"
        )}

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setPrompt("");
          setTimeout(() => {
            setResult("");
          }, 1000);
        }}
      >
        <Modal.Header>Prompt Assistant</Modal.Header>
        <Modal.Body>
          <Input
            as={"textarea"}
            rows={5}
            value={prompt}
            className="mb-4"
            placeholder="Contoh: `Saya ingin membuatkan deskripsi untuk produk saya, tapi saya tidak tahu harus mulai dari mana. Tolong bantu saya membuatkan deskripsi yang bagus.`"
            onChange={(e) => setPrompt(e)}
          />

          <label htmlFor="General Instruction" className="font-[500]">
            General Instruction
          </label>
          <Input
            as={"textarea"}
            rows={5}
            value={instruction}
            className="mb-4"
            placeholder="Contoh: `Anda adalah seorang Marketing analis, dan saya ingin membuatkan deskripsi untuk produk saya. Jangan pernah menggunakan nama produk, perusahaan, atau kata-kata kunci. Jangan pernah menggunakan kalimat yang membosankan dan tidak bermanfaat. Jangan pernah menggunakan tanda baca. Jangan pernah menggunakan kata-kata yang tidak sopan.`"
            onChange={(e) => setInstruction(e)}
          />
          {result && (
            <>
              <label htmlFor="Result" className="font-[500]">
                Result
              </label>
              <Input
                as={"textarea"}
                rows={5}
                value={result}
                className="mb-4"
                placeholder="Hasil dari prompt yang telah diinputkan"
                onChange={(e) => setResult(e)}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance="primary"
            color="blue"
            loading={isLoading}
            onClick={() => {
              setIsLoading(true);
              promptAssistant({ prompt, instruction })
                .then((e) => e.json())
                .then((res) => {
                //   console.log(res.data);
                  setResult(res.data);
                  if (skipReview) {
                    onSave(res.data);
                    setModalOpen(false);
                  }
                })
                .catch(errorToast)
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            Kirim
          </Button>
          {result && (
            <Button
              appearance="ghost"
              color="blue"
              onClick={() => {
                onSave(result);
                setModalOpen(false);
              }}
            >
              Simpan
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AiButton;
