import { Company, CompanyReq } from "../model/company";
import { PaginationReq } from "../objects/pagination";
import { customFetch } from "../utils/http";

export const createCompany = async (company: Company) => {
  return await customFetch("company", {
    method: "POST",
    body: JSON.stringify(company),
  });
};

export const getCompanies = async (pagination: PaginationReq) => {
  const params: Record<string, string> = {
    page: pagination.page.toString(),
    limit: pagination.limit.toString(),
  };
  if (pagination.search && pagination.search != "") {
    params["search"] = pagination.search;
  }
  return await customFetch(`company?${new URLSearchParams(params)}`);
};

export const getCompany = async (id: string) => {
  return await customFetch(`company/${id}`);
};

export const updateCompany = async (id: string, company: CompanyReq) => {
  return await customFetch(`company/${id}`, {
    method: "PUT",
    body: JSON.stringify(company),
  });
};
export const addRapidPlugin = async (
  id: string,
  req: {
    rapid_api_plugin_api: string;
    rapid_api_key: string;
    rapid_api_host: string;
  }
) => {
  return await customFetch(`company/${id}/rapidApi`, {
    method: "POST",
    body: JSON.stringify(req),
  });
};
export const removeRapidPlugin = async (
  id: string,
  apiId: string
) => {
  return await customFetch(`company/${id}/rapidApi/${apiId}`, {
    method: "DELETE",
  });
};
