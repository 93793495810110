import React, {
  useContext,
  useEffect,
  useState,
  type FC,
  type ReactNode,
} from "react";
import { BgColorContext } from "../objects/color_object";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { getProfile } from "../repositories/my";
import { User } from "../model/user";
import { PermissionsContext } from "../objects/permissions_object";
import { LoadingContext } from "../objects/loading";
import { getCompanyStorage, setProfileStorage } from "../utils/storage";
import { Company } from "../model/company";
import { CompanyContext } from "../objects/company_context";
import { initials, requestPermission } from "../utils/helper";
import { getInboxes } from "../repositories/inbox";
import { Inbox } from "../model/inbox";
import { HiOutlineInbox } from "react-icons/hi2";
import { GoHome, GoMention } from "react-icons/go";
import { PiUsers } from "react-icons/pi";
import { Avatar } from "rsuite";
import CustomAvatar from "./CustomAvatar";
import { useNavigate } from "react-router-dom";

interface MainLayoutProps {
  children: ReactNode;
  topBarWidget?: ReactNode;
  onLoadProfile?: (user?: User) => void;
  onLoadPermissions?: (val: string[]) => void;
}

const MainLayout: FC<MainLayoutProps> = ({
  children,
  onLoadProfile,
  topBarWidget,
  onLoadPermissions,
}) => {
  const { color, setColor } = useContext(BgColorContext);
  const [mounted, setMounted] = useState(false);
  const [profile, setProfile] = useState<User | null>(null);
  const { permissions, setPermissions } = useContext(PermissionsContext);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { company, setCompany } = useContext(CompanyContext);
  const nav = useNavigate()

  useEffect(() => {
    setMounted(true);
    requestPermission();
  }, []);

  useEffect(() => {
    if (!mounted) return;

    getProfile()
      .then((res) => res.json())
      .then((v) => {
        setProfile(v.data);
        setProfileStorage(v.data);
        if (onLoadProfile) onLoadProfile(v.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getCompanyStorage()
      .then((v) => {
        setCompany(v);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [mounted]);

  useEffect(() => {
    if (profile) {
      if ((profile.companies ?? []).length == 0) {
        window.location.href = "/onboarding";
      }
      setPermissions(profile?.permissions ?? []);
      if (onLoadPermissions) onLoadPermissions(profile?.permissions ?? []);
    }
  }, [profile]);

  return (
    <div
      className="flex flex-col h-screen"
      style={{
        backgroundColor: color,
      }}
    >
      {isLoading && (
        <div className="fixed w-screen h-screen bg-white bg-opacity-70">
          <div className="flex items-center justify-center h-full">
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-orange-600"></div>
          </div>
        </div>
      )}
      <Topbar>{topBarWidget}</Topbar>
      <div
        className="flex flex-row flex-grow flex-shrink basis-0 flex-1 "
        style={{ backgroundColor: color, paddingTop: 10 }}
      >
        {profile && <Sidebar profile={profile} />}
        <div className=" md:p-0 pb-16" style={{ 
          width: "calc(100% - 280px)",
         }}>{children}</div>
        <div className="fixed bottom-0 w-full bg-white shadow-t p-2 flex justify-around md:hidden">
          <button className="flex flex-col items-center" onClick={() => nav(`/`)}>
            <GoHome size={24} />
            <span className="text-xs">Home</span>
          </button>
          <button className="flex flex-col items-center" onClick={() => nav(`/inbox`)}>
            <HiOutlineInbox size={24} />
            <span className="text-xs">Inbox</span>
          </button>
          <button className="flex flex-col items-center" onClick={() => nav("/my-work")}>
            <GoMention size={24} />
            <span className="text-xs">My Work</span>
          </button>
          <button className="flex flex-col items-center" onClick={() => nav(`/profile`)}>
            <CustomAvatar
              size={24}
              src={profile?.picture_url}
              alt={profile?.full_name}
            />
            <span className="text-xs">Profile</span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default MainLayout;
