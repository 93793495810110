import type { FC, ReactNode } from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { PiMagnifyingGlass } from "react-icons/pi";
import { RiUserAddLine } from "react-icons/ri";
import { ResizableBox } from "react-resizable";
import { Divider, Modal, Pagination as RSPagination } from "rsuite";
import MainLayout from "../components/MainLayout";
import SearchPeople from "../components/SearchPeople";
import { Client } from "../model/client";
import { Role } from "../model/role";
import { Team } from "../model/team";
import { User } from "../model/user";
import { BgColorContext } from "../objects/color_object";
import { LoadingContext } from "../objects/loading";
import { Pagination } from "../objects/pagination";
import { getClients } from "../repositories/client";
import { getRoles } from "../repositories/general";
import { getTeams } from "../repositories/team";
import { initials } from "../utils/helper";
import { errorToast } from "../utils/helper-ui";
import { Invitation } from "../model/invitation";
import { getInvitations } from "../repositories/invitation";

interface ClientPageProps {}

const ClientPage: FC<ClientPageProps> = ({}) => {
  const [profile, setProfile] = useState<User>();
  const { color, setColor } = useContext(BgColorContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(18);
  const [search, setSearch] = useState("");
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [clients, setClients] = useState<Client[]>([]);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [modalInvite, setModalInvite] = useState(false);
  const [boxWidth, setBoxWidth] = useState([
    0, 300, 200, 200, 140, 500, 100, 100,
  ]);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);

  const [teams, setTeams] = useState<Team[]>([]);
  const [modalDetail, setModalDetail] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [roles, setRoles] = useState<Role[]>([]);
  const [searchRole, setSearchRole] = useState("");
  const [invitations, setInvitations] = useState<Invitation[]>([]);

  useEffect(() => {
    setMounted(true);
    getTeams({ page: 1, limit: 0 })
      .then((res) => res.json())
      .then((v) => {
        setTeams(v.data);
      });
  }, []);

  useEffect(() => {
    if (!mounted) return;
    getAllClients();
    getRoles({ page: 1, limit: 5, search: searchRole })
      .then((resp) => resp.json())
      .then((resp) => setRoles(resp.data));
    getInvitations({ page: 1, limit: 0, type: "CLIENT", accepted: "0" })
      .then((resp) => resp.json())
      .then((resp) => setInvitations(resp.data));
  }, [mounted, page, limit, search]);

  const getAllClients = async () => {
    try {
      setIsLoading(true);
      const res = await getClients({ page, limit, search });
      const resJSON = await res.json();
      setClients(resJSON.data);
      setPagination(resJSON.pagination);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const resizable = (
    index: number,
    children: ReactNode,
    addWidth: number = 0
  ) => {
    // let width =  (boxWidth[index] + addWidth) / boxWidth.reduce((a,b) => a+b, 0)
    // console.log(index, `${width*100}%`, addWidth)
    return (
      <ResizableBox
        width={boxWidth[index] + addWidth}
        axis="x"
        minConstraints={[100, 0]}
        resizeHandles={["e"]}
        onResize={(e, data) => {
          //   console.log(data.size);
          boxWidth[index] = data.size.width;
          setBoxWidth([...boxWidth]);
        }}
      >
        {children}
      </ResizableBox>
    );
  };

  const renderEmpty = () => (
    <div className="flex flex-row items-center text-sm">
      <div
        className=" border-b border-r  border-l-4 border-l-orange-400  h-10 p-1 flex flex-col items-center justify-center"
        style={{ borderLeftColor: color }}
      >
        <div className="w-[36px]"></div>
      </div>
      {resizable(
        1,
        <div
          className="cell-resizable-footer cell-resizable-bottom text-xs"
          style={{ fontWeight: "normal" }}
        >
          No Data
        </div>,
        boxWidth[2] + boxWidth[3] + boxWidth[4] + boxWidth[5]
      )}
      <div
        className="border-b  border-r-4 border-r-orange-400   h-10 py-1 px-2  flex items-center truncate text-ellipsis font-[500] flex-1"
        style={{ borderRightColor: color }}
      ></div>
    </div>
  );

  return (
    <MainLayout
      onLoadProfile={setProfile}
      topBarWidget={
        <div className="flex items-center space-x-2 relative">
          <PiMagnifyingGlass className="top-2.5 right-2 absolute" />
          <input
            type="text"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder="Search Client ..."
            className="focus:ring-0 focus:outline-none focus:ring-gray-200 rounded-2xl py-2 px-4 text-xs w-[300px]"
          />
        </div>
      }
    >
      <div
        className="flex flex-col flex-1 overflow-x-auto  h-full"
        style={{ backgroundColor: color }}
      >
        <div className="  h-full bg-white ml-4 rounded-tl-[15px]  flex flex-col">
          <div className="p-4">
            <div className="flex flex-row justify-between items-center">
              <h1 className=" text-2xl font-[500]">Client</h1>
              {/* <button
                className="rounded-lg border bg-white flex flex-row gap-2 px-4 py-2 text-xs text-gray-600 hover:bg-gray-100 items-center"
                onClick={() => setModalInvite(true)}
              >
                <RiUserAddLine /> Invite Client
              </button> */}
            </div>
            <div className="mt-8">
              <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-6 gap-4 gap-y-4">
                {(clients ?? []).map((e) => (
                  <div
                    key={e.id}
                    onClick={() => {
                      setSelectedClient(e);
                      setModalDetail(true);
                    }}
                  >
                    <div className="border aspect-square rounded-lg flex flex-col justify-start text-white bg-gray-100  hover:shadow-lg cursor-pointer">
                      <div className="p-4 rounded-t-lg h-[66.66%] w-full  bg-violet-700 justify-center items-center flex flex-col relative">
                        {e.user?.picture_url ? (
                          <img
                            src={e.user?.picture_url}
                            className=" object-cover w-full h-full rounded-t-lg"
                          />
                        ) : (
                          <h1 className="text-4xl font-bold">
                            {initials(e.user?.full_name)}
                          </h1>
                        )}
                        {e.client_company && (
                          <div className="absolute bottom-2 right-2 bg-black px-2 bg-opacity-20 rounded-xl">
                            <small>{e.client_company?.name}</small>
                          </div>
                        )}
                      </div>
                      <h1 className="text-shadow-sm mb-2 p-2 text-gray-800 flex-col flex flex-1">
                        <span className="text-lg font-[500]">
                          {e.user?.full_name}
                        </span>

                        <small>{e.user?.email}</small>
                      </h1>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {(pagination?.total_pages ?? 0) > 1 && (
            <div className="p-4">
              <RSPagination
                prev
                last
                next
                first
                size="xs"
                total={pagination?.total_records ?? 100}
                limit={limit ?? 10}
                activePage={page}
                onChangePage={(val) => setPage(val)}
                maxButtons={5}
              />
            </div>
          )}
          <Divider />
          <div className="p-4">
            <div className="flex flex-row justify-between items-center">
              <h1 className=" text-2xl font-[500]">Invitation</h1>
              <button
                className="rounded-lg border bg-white flex flex-row gap-2 px-4 py-2 text-xs text-gray-600 hover:bg-gray-100 items-center"
                onClick={() => setModalInvite(true)}
              >
                <RiUserAddLine /> Invite Client
              </button>
            </div>
            <div className="mt-8">
              <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-6 gap-4 gap-y-4">
                {(invitations ?? []).map((e) => (
                  <div key={e.id} onClick={() => {}}>
                    <div className="border aspect-auto rounded-lg flex flex-col justify-start text-white bg-gray-100  hover:shadow-lg cursor-pointer">
                      <h1 className="text-shadow-sm  p-2 text-gray-800 flex-col flex">
                        <span className="text-lg font-[500] truncate line-clamp-2">
                          {e.email}
                        </span>
                      </h1>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={modalInvite} onClose={() => setModalInvite(false)}>
        <Modal.Header>
          <Modal.Title>Invite Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalInvite && (
            <SearchPeople
              invitationType="CLIENT"
              externalOnly
              onSuccess={() => {
                setModalInvite(false);
                getAllClients();
                getInvitations({
                  page: 1,
                  limit: 0,
                  type: "CLIENT",
                  accepted: "0",
                })
                  .then((resp) => resp.json())
                  .then((resp) => setInvitations(resp.data));
              }}
            />
          )}
        </Modal.Body>
      </Modal>
      <Modal open={modalDetail} onClose={() => setModalDetail(false)}>
        <Modal.Header>
          <Modal.Title>Client Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col space-y-4">
            <div>
              <h3 className="font-[500]">Name</h3>
              <p>{selectedClient?.user?.full_name}</p>
            </div>
            <div>
              <h3 className="font-[500]">Email</h3>
              <p>{selectedClient?.user?.email}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </MainLayout>
  );
};
export default ClientPage;
