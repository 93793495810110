// ChannelList.tsx
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BsPersonVideo2, BsReply } from "react-icons/bs";
import { FaCircleXmark } from "react-icons/fa6";
import { HiPaperClip, HiXMark } from "react-icons/hi2";
import { IoAttach } from "react-icons/io5";
import { LuMoreVertical } from "react-icons/lu";
import { VscSend } from "react-icons/vsc";
import { Mention, MentionsInput } from "react-mentions";
import { useParams } from "react-router-dom";
import { Button, Drawer, Dropdown, Input, Loader, Modal, Toggle } from "rsuite";
import { Channel } from "../model/channel";
import { ChatMessage, ChatMessageWebsocket } from "../model/chat_message";
import { Client } from "../model/client";
import { MentionReq } from "../model/comment";
import { FileResp } from "../model/file";
import { Member } from "../model/member";
import { User } from "../model/user";
import { COMPANY_ID, TOKEN } from "../objects/constants";
import { LoadingContext } from "../objects/loading";
import ReactMarkdown from "react-markdown";

import {
  addMember,
  createChannelMessage,
  getChannel,
  getChannelMessage,
  updateChannel,
} from "../repositories/channel";
import { uploadFile } from "../repositories/file";
import { getMembers } from "../repositories/member";
import { checkPermission, handleOnPaste, randomStr } from "../utils/helper";
import { errorToast, parseMentions } from "../utils/helper-ui";
import { asyncLocalStorage, getProfileStorage } from "../utils/storage";
import CustomAvatar from "./CustomAvatar";
import JitsiConference from "./JitsiConference";
import {
  BiChat,
  BiCheckCircle,
  BiCopy,
  BiInfoCircle,
  BiPlusCircle,
  BiUserCircle,
  BiUserPlus,
} from "react-icons/bi";
import { PiChatSlashBold, PiPerson } from "react-icons/pi";
import { BgColorContext } from "../objects/color_object";
import { ProjectContext } from "../objects/project_object";
import InlineForm from "./InlineForm";
import { PermissionsContext } from "../objects/permissions_object";
import { CompanyContext } from "../objects/company_context";

const ChannelList: React.FC = () => {
  const { color, setColor } = useContext(BgColorContext);
  const { permissions, setPermissions } = useContext(PermissionsContext);
  const messageEndRef = useRef<HTMLDivElement>(null);
  const { channelId } = useParams();
  const [mounted, setMounted] = useState(false);
  const [token, setToken] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [clients, setClients] = useState<Client[]>([]);
  const [channel, setChannel] = useState<Channel>();
  const [members, setMembers] = useState<Member[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [profile, setProfile] = useState<User>();
  const [isFocus, setIsFocus] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [msg, setMsg] = useState("");
  const [files, setFiles] = useState<FileResp[]>([]);
  const [mentions, setMentions] = useState<MentionReq[]>([]);
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const [connectedWsID, setConnectedWsID] = useState("");
  const [preview, setPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedMessage, setSelectedMessage] = useState<ChatMessage>();
  const [threadMessage, setThreadMessage] = useState<ChatMessage>();
  const [threadOpen, setThreadOpen] = useState(false);
  const [videoMode, setVideoMode] = useState(false);
  const [hideChatList, setHideChatList] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const { project: activeProject, setProject: setActiveProject } =
    useContext(ProjectContext);
  const [modalParticipant, setModalParticipant] = useState(false);
  const [useGeminiAi, setUseGeminiAi] = useState(false);
  const [geminiSystemInstruction, setGeminiSystemInstruction] = useState("");
  const { company, setCompany } = useContext(CompanyContext);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    setMounted(true);
    asyncLocalStorage.getItem(TOKEN).then((v) => {
      setToken(v);
    });
    asyncLocalStorage.getItem(COMPANY_ID).then((v) => {
      setCompanyID(v);
    });
    getProfileStorage().then((res) => setProfile(res));
  }, [mounted]);
  useEffect(() => {
    if (!mounted) return;

    getMembers({ page: 1, limit: 0 })
      .then((res) => res.json())
      .then((res) => {
        setMembers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    if (channelId) {
      getDetail().then(() => {
        scrollToBottom();
      });

      if (token) {
        if (channelId && connectedWsID != channelId) {
          var url = `${process.env.REACT_APP_API_URL}/ws/channel/${channelId}?token=${token}`;
          var ws = new WebSocket(url);

          ws.onopen = function (event) {
            console.log("Connection established");
          };
          ws.onmessage = function (event) {
            let resp: ChatMessageWebsocket = JSON.parse(event.data);
            // console.log("Message from server: ", resp);
            // console.log("messages: ", messages);
            // console.log("resp.data.parent_id: ", resp.data.parent_id);
            if (resp.data.parent_id) {
              getChannelMessage(channelId!, resp.data.parent_id)
                .then((res) => res.json())
                .then((res) => {
                  // console.log(res.data.id);
                  setMessages((prevMessages) => {
                    return [
                      ...prevMessages.map((e) => {
                        if (e.id == res.data.id) {
                          console.log(e);
                          e.reply_count = res.data.reply_count;
                        }
                        return e;
                      }),
                    ];
                  });
                });
            } else {
              setMessages((prevMessages) => {
                return [
                  ...prevMessages,
                  {
                    ...resp.data,
                    id: resp.id,
                  },
                ];
              });
              setTimeout(() => {
                scrollToBottom();
              }, 300);
            }
          };

          setWebsocket(ws);
          setConnectedWsID(channelId!);
          return () => {
            console.log("Connection closed");
            ws.close();
          };
        }
      }
    }
  }, [channelId, mounted, token]);

  const getDetail = async () => {
    return getChannel(channelId!)
      .then((res) => res.json())
      .then((res) => {
        setChannel(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Function to scroll to the bottom of the message list
  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {}, [messages]);

  useEffect(() => {
    if (channel) {
      let messages = channel.messages ?? [];
      setMessages(messages.reverse());
      setUseGeminiAi(channel.use_gemini_ai);
      setGeminiSystemInstruction(channel.gemini_system_instruction);
    }
  }, [channel]);

  const sendChat = () => {
    if (!websocket) {
      errorToast("Web socket not connected");
      return;
    }
    let dataMsg: ChatMessage = {
      id: window.self.crypto.randomUUID(),
      feature_data: "{}",
      avatar: profile?.picture_url!,
      full_name: profile?.full_name!,
      created_at: new Date().toISOString(),
      member_id: profile?.member?.id ?? null,
      client_id: profile?.client?.id ?? null,
      channel_id: channel?.id!,
      content: msg.trim(),
      message_type: "TEXT",
      files: files,
      parent_id: threadMessage?.id ?? selectedMessage?.id ?? null,
    };
    let req: ChatMessageWebsocket = {
      id: window.self.crypto.randomUUID(),
      type: "MESSAGE",
      action: "CREATE",
      targetId: channel?.id!,
      targetType: "CHANNEL",
      userId: profile?.id!,
      data: dataMsg,
    };

    createChannelMessage(channelId!, dataMsg)
      .then((res) => res.json())
      .then((res) => {
        websocket.send(JSON.stringify(req));
        if (threadMessage) {
          getMessage(threadMessage);
        }
      });
    setTimeout(() => {
      setMsg("");
      setFiles([]);
      setSelectedMessage(undefined);
    }, 100);
  };

  const styles: any = {
    control: {
      backgroundColor: "transparent",
      fontSize: 14,
      fontWeight: "normal",
    },

    "&multiLine": {
      control: {},
      highlighter: {},
      input: {
        outline: "none",
        paddingRight: 56,
      },
      maxHeight: 60,
    },

    "&singleLine": {
      display: "inline-block",
      width: 180,

      highlighter: {
        padding: 1,
        border: "2px inset transparent",
      },
      input: {
        padding: 1,
        border: "2px inset",
      },
    },

    suggestions: {
      list: {
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 14,
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        textAlign: "left",
        "&focused": {
          backgroundColor: "#ccc0e1",
        },
      },
    },
  };

  const handleMemberClick = (memberId: string) => {
    console.log("Member clicked, memberId:", memberId);
    // Example: Navigate to the member's profile or trigger another action
  };

  const handleClientClick = (clientId: string) => {
    console.log("Client clicked, clientId:", clientId);
    // Example: Navigate to the client's profile or trigger another action
  };
  const handleChannelClick = (clientId: string) => {
    console.log("Channel clicked, clientId:", clientId);
    // Example: Navigate to the client's profile or trigger another action
  };
  const handleUpload = async (val: any) => {
    if (!val?.target) return null;
    const input = val.target as HTMLInputElement;

    try {
      setIsLoading(true);

      const resp = await uploadFile(input?.files![0], (progress) => {
        console.log(progress);
      });
      return await resp.json();
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const getMessage = (message: ChatMessage) => {
    setIsLoading(true);
    getChannelMessage(channelId!, message.id)
      .then((v) => v.json())
      .then((v) => {
        setThreadMessage(v.data);
        setThreadOpen(true);
      })
      .catch((e) => {
        errorToast(`${e}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderInputFile = () => (
    <input
      type="file"
      accept="image/*"
      ref={fileRef}
      className="hidden"
      onChange={async (val) => {
        try {
          setIsLoading(true);
          var resp = await handleUpload(val);
          const extension = resp.data.path.split(".").pop() ?? "";
          setFiles([
            ...files,
            {
              ...resp.data!,
              is_image: ["jpg", "png", "jpeg", "gif", "webp"].includes(
                extension.toLowerCase()
              ),
            },
          ]);
        } catch (error) {
          errorToast(`${error}`);
        } finally {
          setIsLoading(false);
        }
      }}
    />
  );

  const renderSelectedMessage = () => {
    if (!selectedMessage) return null;
    return (
      <div
        className="p-4 bg-gray-100 flex flex-row justify-between items-center border-l-[7px]"
        style={{ borderLeftColor: color }}
      >
        <div>
          <div className="flex items-center space-x-2">
            <span className="font-semibold">{selectedMessage?.full_name}</span>
            <span className="text-xs text-gray-500">
              {moment(selectedMessage?.created_at).fromNow()}
            </span>
          </div>
          <div>
            {parseMentions(
              selectedMessage?.content,
              (type: string, id: string) => {
                if (type == "member") handleMemberClick(id);
                if (type == "client") handleClientClick(id);
                if (type == "channel") handleChannelClick(id);
              }
            )}
          </div>
        </div>
        <HiXMark
          className="text-red-400 cursor-pointer"
          onClick={() => setSelectedMessage(undefined)}
        />
      </div>
    );
  };
  const renderChatBox = () => (
    <div className="relative w-full  pt-4">
      <MentionsInput
        className="custom-input"
        inputRef={inputRef}
        ref={(val) => {}}
        forceSuggestionsAboveCursor={true}
        placeholder={`What's on your mind, ${
          profile?.full_name
        }, press shift+enter to new line ${
          company?.gemini_api_key && `use /ai prefix to ask ai assistant`
        }`}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onPaste={async (event) => {
          handleOnPaste(
            event,
            (progress: number) => {},
            async (v: { data: FileResp }) => {
              try {
                setIsLoading(true);
                const extension = v.data.path.split(".").pop() ?? "";
                setFiles([
                  ...files,
                  {
                    id: randomStr(20),
                    filename: v.data.filename!,
                    path: v.data.path,
                    url: v.data.url,
                    storage_provider: v.data.storage_provider,
                    is_image: ["jpg", "png", "jpeg", "gif", "webp"].includes(
                      extension.toLowerCase()
                    ),
                  },
                ]);
                setMsg("");
              } catch (error) {
                errorToast(`${error}`);
              } finally {
                setIsLoading(false);
              }
            }
          );
        }}
        onKeyUp={(e) => {
          if (e.shiftKey && e.key === "Enter") {
            e.preventDefault();
          } else if (e.key === "Enter") {
            sendChat();
          }
        }}
        value={msg}
        onChange={(val, _, __, mentions) => {
          setMsg(val.target.value);

          let mentionReqs: MentionReq[] = [];
          for (let index = 0; index < mentions.length; index++) {
            const m = mentions[index];
            let mention: MentionReq = {
              data: JSON.stringify(m),
            };
            // console.log(msg[m.index]);
            if (msg[m.index] == "@") {
              mention.member_id = m.id;
            }
            if (msg[m.index] == "!") {
              mention.client_id = m.id;
            }
            if (msg[m.index] == "#") {
              mention.channel_id = m.id;
            }
            mentionReqs.push(mention);
          }
          // console.log(mentionReqs);
          setMentions(mentionReqs);
        }}
        style={styles}
      >
        <Mention
          trigger="@"
          data={members.map((e) => ({
            id: e.id,
            display: e.user?.full_name,
          }))}
          style={{
            backgroundColor: "#ccc0e1",
          }}
        />
        <Mention
          trigger="!"
          data={clients.map((e) => ({
            id: e.id,
            display: e.user?.full_name,
          }))}
          style={{
            backgroundColor: "#ffff00",
          }}
        />
        <Mention
          trigger="#"
          data={channels.map((e) => ({
            id: e.id,
            display: e.name,
          }))}
          style={{
            backgroundColor: "#32cd32",
          }}
        />
      </MentionsInput>
    </div>
  );
  const renderChatList = () => (
    <>
      {messages.length <= 10 && (
        <div className="p-4 mb-6 bg-gray-100 rounded-lg mt-[400px]">
          <h1 className="text-2xl font-bold">Welcome to #{channel?.name}!</h1>
          <p className="text-gray-600">
            This is the start of the #{channel?.name} channel.
          </p>
        </div>
      )}
      {/* Message List */}
      {channel?.is_participant && (
        <div className="flex flex-col ">
          {messages.map((message, index) => {
            const isSameUserAsPrevious =
              index > 0 && messages[index - 1].member_id === message.member_id;
            return (
              <div
                key={message.id}
                className="flex items-start space-x-2 px-2 py-4 rounded-lg last:mb-0 hover:bg-gray-100 group/item relative hover:border-l-[7px]"
                style={{
                  borderLeftColor: color,
                }}
              >
                <div className="absolute -top-4 bg-white py-1 px-2 rounded-lg right-1 cursor-pointer group/edit invisible  group-hover/item:visible flex flex-row justify-end space-x-2">
                  <BiCopy
                    size={24}
                    className="text-blue-200 hover:text-blue-600"
                    onClick={() => {
                      navigator.clipboard.writeText(message.content);
                    }}
                  />
                  <BsReply
                    size={24}
                    className="text-blue-200 hover:text-blue-600"
                    onClick={() => {
                      setTimeout(() => {
                        inputRef.current?.focus();
                        setSelectedMessage(message);
                      }, 300);
                    }}
                  />
                </div>
                <div className="w-8 h-8">
                  {!isSameUserAsPrevious && (
                    <CustomAvatar
                      src={message.avatar}
                      alt={message.full_name}
                      size={28}
                    />
                  )}
                </div>
                <div
                  className="flex-1 cursor-pointer"
                  onClick={() => getMessage(message)}
                >
                  <div className="flex items-center space-x-2">
                    <span className="font-semibold">{message.full_name}</span>
                    <span className="text-xs text-gray-500">
                      {moment(message.created_at).fromNow()}
                    </span>
                  </div>
                  {!message.is_ai && (
                    <div>
                      {parseMentions(
                        message.content,
                        (type: string, id: string) => {
                          if (type == "member") handleMemberClick(id);
                          if (type == "client") handleClientClick(id);
                          if (type == "channel") handleChannelClick(id);
                        }
                      )}
                    </div>
                  )}
                  {message.is_ai && (
                    <ReactMarkdown>{message.content}</ReactMarkdown>
                  )}

                  {message.files.length > 0 && (
                    <div className="h-10 w-full flex flex-row gap-2">
                      {message.files.map((e) => (
                        <div
                          className="aspect-square w-10 h-10 flex flex-col justify-center items-center bg-gray-100 hover:bg-gray-300 cursor-pointer rounded-lg"
                          key={e.url}
                        >
                          {e.is_image ? (
                            <img
                              src={e.url}
                              className=" rounded-lg object-cover  w-10 h-10 cursor-pointer"
                              onClick={() => {
                                setPreview(true);
                                setPreviewUrl(e.url);
                              }}
                            />
                          ) : (
                            <HiPaperClip
                              className="cursor-pointer"
                              onClick={() => {
                                window.open(e.url);
                              }}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                  {(message?.reply_count ?? 0) > 0 && (
                    <div className="text-xs px-2 py-0.5 my-2 hover:bg-gray-800 hover:text-white cursor-pointer bg-gray-100 rounded-lg w-fit">
                      {message?.reply_count} Replies
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          {/* Reference div for auto-scroll */}
          <div ref={messageEndRef} />
        </div>
      )}
      {!channel?.is_participant && (
        <div className="flex flex-col">
          <div className="flex items-center space-x-2 flex-col">
            <span className="font-semibold">You are not a participant</span>

            <span className="font">
              Please Contact{" "}
              <span className="font-bold">#{channel?.name} </span>
              Admin
            </span>
          </div>
        </div>
      )}
    </>
  );

  const renderFileList = () => (
    <>
      {files.length > 0 && (
        <div className="px-4 py-2 bg-gray-50 hover:bg-gray-200">
          <div className="h-10 w-full flex flex-row gap-2 ">
            {files.map((e) => (
              <div
                className="aspect-square w-10 h-10 flex flex-col justify-center items-center bg-gray-100 hover:bg-gray-300 cursor-pointer rounded-lg relative"
                key={randomStr(7)}
              >
                <FaCircleXmark
                  className="absolute top-0 right-0 w-4 text-red-400"
                  onClick={() => {
                    setFiles(files.filter((f) => f.id !== e.id));
                  }}
                />
                {e.is_image ? (
                  <img
                    src={e.url}
                    className=" rounded-lg object-cover  w-10 h-10"
                    onClick={() => {
                      setPreview(true);
                      setPreviewUrl(e.url);
                    }}
                  />
                ) : (
                  <HiPaperClip onClick={() => window.open(e.url)} />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );

  const renderModal = () => (
    <Modal open={preview} onClose={() => setPreview(false)}>
      <Modal.Body style={{}}>
        {previewUrl && (
          <img src={previewUrl} className="w-full h-full rounded-lg " />
        )}
      </Modal.Body>
    </Modal>
  );
  const renderDrawer = () => (
    <Drawer
      open={threadOpen}
      onClose={() => {
        setThreadOpen(false);
        setThreadMessage(undefined);
      }}
    >
      <Drawer.Header>
        <Drawer.Title>
          <strong>Thread Message </strong>
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body style={{ padding: 0 }}>
        <div className="flex flex-col h-full">
          <div className="flex-1  h-full overflow-y-auto px-4 ">
            {threadMessage && (
              <div className="flex items-start space-x-2 px-2 py-4 rounded-lg last:mb-0  group/item relative">
                <div className="w-8 h-8">
                  <CustomAvatar
                    src={threadMessage.avatar}
                    alt={threadMessage.full_name}
                    size={28}
                  />
                </div>
                <div className="flex-1 flex flex-col">
                  <div className="flex items-center space-x-2">
                    <span className="font-semibold">
                      {threadMessage.full_name}
                    </span>
                    <span className="text-xs text-gray-500">
                      {moment(threadMessage.created_at).fromNow()}
                    </span>
                  </div>
                  <div className="mb-2">
                    {threadMessage.is_ai && (
                      <ReactMarkdown>{threadMessage.content}</ReactMarkdown>
                    )}
                    {!threadMessage.is_ai &&
                      parseMentions(
                        threadMessage.content,
                        (type: string, id: string) => {
                          if (type == "member") handleMemberClick(id);
                          if (type == "client") handleClientClick(id);
                          if (type == "channel") handleChannelClick(id);
                        }
                      )}
                  </div>
                  {threadMessage.files.length > 0 && (
                    <div className="h-10 w-full flex flex-row gap-2">
                      {threadMessage.files.map((e) => (
                        <div
                          className="aspect-square w-10 h-10 flex flex-col justify-center items-center bg-gray-100 hover:bg-gray-300 cursor-pointer rounded-lg"
                          key={e.id}
                        >
                          {e.is_image ? (
                            <img
                              src={e.url}
                              className=" rounded-lg object-cover  w-10 h-10 cursor-pointer"
                              onClick={() => {
                                setPreview(true);
                                setPreviewUrl(e.url);
                              }}
                            />
                          ) : (
                            <HiPaperClip
                              className="cursor-pointer"
                              onClick={() => {
                                window.open(e.url);
                              }}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="border-t w-full  my-4"></div>
                  <div>
                    {(threadMessage.replies ?? []).map((reply) => (
                      <div
                        key={reply.id}
                        className="flex items-start space-x-2 px-2 py-4 first:pt-2  last:mb-0  group/item relative  border-b last:border-b-0"
                      >
                        <div className="w-8 h-8">
                          <CustomAvatar
                            src={reply.avatar}
                            alt={reply.full_name}
                            size={28}
                          />
                        </div>
                        <div className="flex-1 flex flex-col">
                          <div className="flex items-center space-x-2">
                            <span className="font-semibold">
                              {reply.full_name}
                            </span>
                            <span className="text-xs text-gray-500">
                              {moment(reply.created_at).fromNow()}
                            </span>
                          </div>
                          <div>
                            {parseMentions(
                              reply.content,
                              (type: string, id: string) => {
                                if (type == "member") handleMemberClick(id);
                                if (type == "client") handleClientClick(id);
                                if (type == "channel") handleChannelClick(id);
                              }
                            )}
                          </div>
                          {reply.files.length > 0 && (
                            <div className="h-10 w-full flex flex-row gap-2">
                              {reply.files.map((e) => (
                                <div
                                  className="aspect-square w-10 h-10 flex flex-col justify-center items-center bg-gray-100 hover:bg-gray-300 cursor-pointer rounded-lg"
                                  key={e.id}
                                >
                                  {e.is_image ? (
                                    <img
                                      src={e.url}
                                      className=" rounded-lg object-cover  w-10 h-10 cursor-pointer"
                                      onClick={() => {
                                        setPreview(true);
                                        setPreviewUrl(e.url);
                                      }}
                                    />
                                  ) : (
                                    <HiPaperClip
                                      className="cursor-pointer"
                                      onClick={() => {
                                        window.open(e.url);
                                      }}
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {renderFileList()}
          <div className="flex flex-row items-center space-x-2 px-2 pb-2 border-t">
            <div className="pt-3">
              <IoAttach
                onClick={() => {
                  fileRef.current?.click();
                }}
              />
            </div>
            <div className="w-full h-fit flex-1">{renderChatBox()}</div>
            <div className="pt-3">
              <VscSend
                onClick={() => {
                  sendChat();
                }}
              />
            </div>
          </div>
        </div>
      </Drawer.Body>
    </Drawer>
  );

  const addParticipant = (participantId: string, type: string) => {
    let data: { member_ids: string[]; client_ids: string[] } = {
      member_ids: [],
      client_ids: [],
    };

    if (type == "member") {
      data.member_ids = [participantId];
    } else if (type == "client") {
      data.client_ids = [participantId];
    }
    setIsLoading(true);
    addMember(channelId!, data)
      .then((res) =>
        getChannel(channelId!)
          .then((res) => res.json())
          .then((res) => {
            setChannel(res.data);
            setModalParticipant(false);
          })
      )
      .catch(errorToast)
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className="flex flex-col w-full h-full relative ">
      <div
        className="absolute top-1 right-1"
        style={{
          zIndex: 99999,
        }}
      >
        <div
          onClick={() => setOpenDrawer(!openDrawer)}
          className="px-3 py-1 bg-gray-50 hover:bg-gray-100 rounded-lg flex flex-row items-center space-x-2 gap-2 text-sm text-gray-500 hover:text-gray-800 cursor-pointer"
        >
          <BiInfoCircle />
          Info
        </div>
      </div>
      <div className="flex flex-row flex-1  h-full overflow-y-auto">
        {videoMode && (
          <div
            className="w-2/3  h-full"
            style={{
              width: hideChatList ? "100%" : "66.66%",
            }}
          >
            <div className="h-full w-full flex flex-col justify-center items-center">
              <JitsiConference
                roomName={`AGENSIA-${channel?.code}`}
                displayName={profile?.full_name ?? ""}
                email={profile?.email ?? ""}
              />
              <h1 className="text-4xl"></h1>
            </div>
          </div>
        )}
        {!hideChatList && (
          <div className="flex-1  h-full overflow-y-auto">
            {renderChatList()}
          </div>
        )}
      </div>
      {renderSelectedMessage()}
      {!threadOpen && renderFileList()}
      {!threadOpen && (
        <div className="flex flex-row items-center space-x-2 border-t">
          <div className="pt-3">
            <IoAttach
              onClick={() => {
                fileRef.current?.click();
              }}
            />
          </div>
          <div className="w-full h-fit flex-1">{renderChatBox()}</div>

          <div className="pt-3">
            <VscSend
              onClick={() => {
                sendChat();
              }}
            />
          </div>
          <div className="pt-3">
            <BsPersonVideo2
              className=" text-gray-600 cursor-pointer "
              onClick={() => {
                setVideoMode(!videoMode);
                if (videoMode) {
                  setHideChatList(false);
                }
              }}
            />
          </div>
          {videoMode && (
            <div className="pt-3">
              <PiChatSlashBold
                className=" text-gray-600 cursor-pointer "
                onClick={() => {
                  setHideChatList(!hideChatList);
                  scrollToBottom();
                }}
              />
            </div>
          )}
        </div>
      )}
      {renderModal()}
      {renderDrawer()}
      {renderInputFile()}

      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}   style={{ 
            zIndex: 9999999
           }}>
        <Drawer.Header>
          <Drawer.Title>{channel?.name}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body style={{ padding: 20 }}>
          <div className="flex flex-row justify-between">
            <h1 className="font-[500] text-xl mb-2">Participants</h1>
            {(channel?.participants ?? []).find(
              (e) => e.user.id == profile?.id && e.role_name == "ADMIN"
            ) && (
              <div className="flex flex-row items-center space-x-2 text-xs">
                <BiUserPlus
                  size={16}
                  className=" text-gray-400 hover:text-gray-800 cursor-pointer"
                  onClick={() => setModalParticipant(true)}
                />
              </div>
            )}
          </div>
          <ul>
            {channel?.participants.map((e) => (
              <li key={e.id} className="mb-4">
                <div className="flex flex-row items-center space-x-2">
                  <CustomAvatar
                    src={e.user.picture_url}
                    alt={e.user.full_name}
                    size={28}
                  />
                  <div>
                    <h3 className="font-[500] -mb-1">{e.user.full_name}</h3>
                    <div className="flex flex-row gap-2">
                      <small>{e.role_name}</small>
                      {profile?.id == e.user.id && (
                        <div className="flex flex-row items-center space-x-2 text-xs">
                          <span className="text-green-500">You</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {permissions.includes("update_channel") && (
            <div className="mt-8">
              <h3 className="text-xl font-[500] mb-2">Channel Setting</h3>
              <InlineForm label="Use Gemini Assistant" paddingY={10}>
                <Toggle
                  checked={useGeminiAi}
                  onChange={(e) => {
                    setUseGeminiAi(e);
                  }}
                />
              </InlineForm>
              <InlineForm
                label="Gemini System Instruction"
                paddingY={10}
                alignStart
              >
                <Input
                  as={"textarea"}
                  rows={9}
                  placeholder="
                  Write your instructions here
                "
                  value={geminiSystemInstruction}
                  onChange={(e) => {
                    setGeminiSystemInstruction(e);
                  }}
                />
              </InlineForm>
              <div className="flex flex-row justify-end mt-8">
                <Button
                  appearance="primary"
                  onClick={() => {
                    if (!company?.gemini_api_key) {
                      errorToast("Please add your gemini api key first");
                      return;
                    }
                    setIsLoading(true);
                    updateChannel(channelId!, {
                      use_gemini_ai: useGeminiAi,
                      gemini_system_instruction: geminiSystemInstruction,
                    })
                      .then((res) =>
                        getChannel(channelId!)
                          .then((res) => res.json())
                          .then((res) => {
                            setChannel(res.data);
                            setModalParticipant(false);
                          })
                      )
                      .catch(errorToast)
                      .finally(() => {
                        setIsLoading(false);
                      });
                  }}
                >
                  Update Channel
                </Button>
              </div>
            </div>
          )}
        </Drawer.Body>
      </Drawer>
      <Modal open={modalParticipant} onClose={() => setModalParticipant(false)}>
        <Modal.Header>Add Participant</Modal.Header>
        <Modal.Body>
          <h3 className="mb-2 font-[500]">Member</h3>
          <ul className="mb-4">
            {(activeProject?.members ?? []).map((e) => {
              let isParticipant =
                channel?.participants
                  ?.map((e) => e.user.id)
                  .includes(e.user.id) ?? false;
              return (
                <li
                  key={e.id}
                  className="flex flex-row justify-between cursor-pointer"
                  onClick={() => {
                    if (!isParticipant) {
                      addParticipant(e.id, "member");
                    }
                  }}
                >
                  <div className="flex flex-row items-center space-x-2">
                    <CustomAvatar
                      src={e.user.picture_url}
                      alt={e.user.full_name}
                      size={28}
                    />
                    <div className="flex flex-row items-center space-x-2">
                      {e.user?.full_name}
                    </div>
                  </div>
                  {isParticipant && (
                    <BiCheckCircle className=" text-green-500" />
                  )}
                </li>
              );
            })}
          </ul>
          <h3 className="mb-2 font-[500]">Client</h3>
          <ul className="mb-4">
            {(activeProject?.clients ?? []).map((e) => {
              let isParticipant =
                channel?.participants
                  ?.map((e) => e.user.id)
                  .includes(e.user.id) ?? false;
              return (
                <li
                  key={e.id}
                  className="flex flex-row justify-between cursor-pointer"
                  onClick={() => {
                    if (!isParticipant) {
                      addParticipant(e.id, "client");
                    }
                  }}
                >
                  <div className="flex flex-row items-center space-x-2">
                    <CustomAvatar
                      src={e.user.picture_url}
                      alt={e.user.full_name}
                      size={28}
                    />
                    <div className="flex flex-row items-center space-x-2">
                      {e.user?.full_name}
                    </div>
                  </div>
                  {isParticipant && (
                    <BiCheckCircle className=" text-green-500" />
                  )}
                </li>
              );
            })}
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ChannelList;
