// PriorityBarChart.tsx
import { ApexOptions } from "apexcharts";
import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { getPriorityColor } from "../../utils/helper";
import { PriorityCount } from "../../model/board";

interface PriorityBarChartProps {
  data: PriorityCount[];
}

const PriorityBarChart: React.FC<PriorityBarChartProps> = ({ data }) => {
  useEffect(() => {
    // console.log(
    //   data.map((item) => {
    //     return getPriorityColor(item.priority);
    //   })
    // );
  }, [data]);
  const chartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show:false,
        tools: {
          download: false,
        }
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
      },
    },
    xaxis: {
      categories: data.map((item) => item.priority),
    },
    colors: ["#FFCDD2", "#ff8a8a", "#eecb02", "#F57C00", "#E53935"],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    yaxis: {
      min: 0,
      max: Math.max(...data.map((item) => item.count)) + 1, // Set max value
      labels: {
        formatter: (val: number) => Math.floor(val).toString(),
      },
    },
    responsive: [
      {
        breakpoint: undefined,
        options: {
        },
      },
    ],
  };

  const series = [
    {
      name: "Task Count",
      data: data.map((item) => item.count),
    },
  ];

  return (
    <div>
      <Chart options={chartOptions as ApexOptions} series={series} type="bar" width={400}/>
    </div>
  );
};

export default PriorityBarChart;
