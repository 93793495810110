import { useContext, useEffect, useRef, useState, type FC } from "react";
import MainLayout from "../components/MainLayout";
import { User } from "../model/user";
import {
  Form,
  FormField,
  FormFieldOption,
  FormFieldType,
  FormReq,
  FormSection,
} from "../model/form";
import { useNavigate, useParams } from "react-router-dom";
import { getForm, updateForm } from "../repositories/form";
import { ProjectContext } from "../objects/project_object";
import { BgColorContext } from "../objects/color_object";
import { LoadingContext } from "../objects/loading";
import { errorToast, successToast } from "../utils/helper-ui";
import { getProjects } from "../repositories/project";
import { Project } from "../model/project";
import { Board, Column } from "../model/board";
import { Button, Dropdown, Modal, SelectPicker, Toggle } from "rsuite";
import { getBoard, getBoards } from "../repositories/board";
import { getColumns } from "../repositories/column";
import { randomStr } from "../utils/helper";
import { randomUUID } from "crypto";
import {
  PiCalendar,
  PiCalendarDots,
  PiPassword,
  PiPlusCircleBold,
  PiRadioButtonFill,
  PiShare,
  PiTrash,
} from "react-icons/pi";
import {
  RiDropdownList,
  RiFileUploadLine,
  RiText,
  RiTextBlock,
} from "react-icons/ri";
import { TbCheckbox, TbNumber123 } from "react-icons/tb";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { RxSwitch } from "react-icons/rx";
import { HiXMark } from "react-icons/hi2";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../components/StrictModeDroppable";
import { IoMove } from "react-icons/io5";
import FormPreview from "../components/FormPreview";
import { FaCode } from "react-icons/fa6";
import FormResponse from "../components/FormResponse";
import FormResponsePage from "../components/FormResponse";

interface FormDetailProps {}

const FormDetail: FC<FormDetailProps> = ({}) => {
  const [form, setForm] = useState<Form | null>(null);
  const { project: activeProject, setProject: setActiveProject } =
    useContext(ProjectContext);
  const [profile, setProfile] = useState<User>();
  const { color, setColor } = useContext(BgColorContext);
  const [mounted, setMounted] = useState(false);
  const { formId } = useParams();
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const nav = useNavigate();
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useState<
    Project | null | undefined
  >(null);
  const [selectedBoard, setSelectedBoard] = useState<Board | null>(null);
  const [selectedColumn, setSelectedColumn] = useState<Column | null>(null);
  const [selectedProjectID, setSelectedProjectID] = useState("");
  const [selectedBoardID, setSelectedBoardID] = useState("");
  const [selectedColumnID, setSelectedColumnID] = useState("");
  const fileRef = useRef<HTMLInputElement>(null);
  const fileRef2 = useRef<HTMLInputElement>(null);
  const [pic, setPic] = useState("");
  const [picUrl, setPicUrl] = useState("");
  const [cover, setCover] = useState("");
  const [coverUrl, setCoverUrl] = useState("");
  const [sections, setSections] = useState<FormSection[]>([]);
  const [mode, setMode] = useState("builder");
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [embedFormCode, setEmbedFormCode] = useState("");
  const [modalEmbedCode, setModalEmbedCode] = useState(false);

  // Function to scroll to the bottom of the container
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      setIsLoading(true);
      getDetail();
    }
  }, [mounted, formId]);

  const getDetail = () => {
    getForm(formId!)
      .then((res) => res.json())
      .then((res) => {
        setForm(res.data);
      })
      .catch(errorToast)
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (form) {
      setIsDisabled(form?.status == "PUBLISH");
      setSections([...form?.sections]);
      getAllProjects("");
      setSelectedProjectID(form?.project_id);
      setSelectedProject(form?.project);
      setSelectedBoardID(form?.board_id);
      setSelectedBoard(form?.board);
      setSelectedColumnID(form?.column_id);
      setSelectedColumn(form?.column);
      getBoards({ page: 1, limit: 0, project_id: form?.project_id })
        .then((res) => res.json())
        .then((res) => {
          setSelectedProject({
            ...selectedProject!,
            boards: res.data,
          });
        })
        .catch(errorToast);
      getColumns({ page: 1, limit: 0, board_id: form?.board_id })
        .then((res) => res.json())
        .then((res) => {
          setSelectedBoard({
            ...selectedBoard!,
            columns: res.data,
          });
        })
        .catch(errorToast);
    }
  }, [form]);
  const getAllProjects = async (search: string) => {
    try {
      const res = await getProjects({
        page: 1,
        limit: 5,
        search,
        project_id: form?.project_id,
      });
      const resJson = await res.json();
      setProjects(resJson.data);
    } catch (error) {
      errorToast(`${error}`);
    }
  };

  useEffect(() => {
    if (selectedProject) {
    }
  }, [selectedProject]);

  const saveForm = (data?: any) => {
    try {
      updateForm(
        form!.id,
        data ?? {
          ...form,
        }
      );
    } catch (error) {
    } finally {
    }
  };
  const addField = (e: FormSection, formFieldType: FormFieldType) => {
    let options: FormFieldOption[] = [];
    if (
      formFieldType == FormFieldType.Checkbox ||
      formFieldType == FormFieldType.RadioButton ||
      formFieldType == FormFieldType.Dropdown
    ) {
      options = [
        {
          label: "Label",
          value: "Value",
        },
      ];
    }
    let newData = [
      ...sections.map((s) => {
        if (s.id == e.id) {
          s.fields = [
            ...s.fields,
            {
              id: randomStr(20),
              label: "New Field",
              type: formFieldType,
              options: options,
              required: false,
              is_multi:
                formFieldType == FormFieldType.Checkbox ||
                formFieldType == FormFieldType.DateRangePicker
                  ? true
                  : false,
              placeholder: "",
              default_value: "",
              help_text: "",
              disabled: false,
            },
          ];
        }
        return s;
      }),
    ];
    setSections(newData);
    updateForm(form!.id, {
      ...form,
      sections: newData,
    });
  };

  const renderIcon = (e: FormFieldType, size: number) => {
    switch (e) {
      case FormFieldType.TextField:
        return <RiText size={size} />;
      case FormFieldType.TextArea:
        return <RiTextBlock size={size} />;
      case FormFieldType.RadioButton:
        return <PiRadioButtonFill size={size} />;
      case FormFieldType.Checkbox:
        return <TbCheckbox size={size} />;
      case FormFieldType.DatePicker:
        return <PiCalendar size={size} />;
      case FormFieldType.DateRangePicker:
        return <PiCalendarDots size={size} />;
      case FormFieldType.NumberField:
        return <TbNumber123 size={size} />;
      case FormFieldType.EmailField:
        return <MdOutlineAlternateEmail size={size} />;
      case FormFieldType.PasswordField:
        return <PiPassword size={size} />;
      case FormFieldType.FileUpload:
        return <RiFileUploadLine size={size} />;
      case FormFieldType.ToggleSwitch:
        return <RxSwitch size={size} />;
      case FormFieldType.Dropdown:
        return <RiDropdownList size={size} />;
    }

    return null;
  };

  const updateField = (
    s: FormSection,
    e: FormField,
    field: string,
    val: any
  ) => {
    setSections(
      sections.map((s1) => {
        if (s1.id === s.id) {
          return {
            ...s1,
            fields: s1.fields.map((f) => {
              if (f.id === e.id) {
                return { ...f, [field]: val };
              }
              return f;
            }),
          };
        }
        return s1;
      })
    );
  };

  const updateOption = (
    s: FormSection,
    e: FormField,
    index: number,
    key: string,
    val: any
  ) => {
    setSections(
      sections.map((s1) => {
        if (s1.id === s.id) {
          return {
            ...s1,
            fields: s1.fields.map((f) => {
              if (f.id === e.id) {
                return {
                  ...f,
                  options: f.options.map((o, i) => {
                    if (i === index) {
                      return { ...o, [key]: val };
                    }
                    return o;
                  }),
                };
              }
              return f;
            }),
          };
        }
        return s1;
      })
    );
  };
  const removeOption = (s: FormSection, e: FormField, i: number) => {
    setSections(
      sections.map((s1) => {
        if (s1.id === s.id) {
          return {
            ...s1,
            fields: s1.fields.map((f) => {
              if (f.id === e.id) {
                return {
                  ...f,
                  options: f.options.filter((o, j) => i !== j),
                };
              }
              return f;
            }),
          };
        }
        return s1;
      })
    );
  };
  const addOption = (s: FormSection, e: FormField) => {
    setSections(
      sections.map((s1) => {
        if (s1.id === s.id) {
          return {
            ...s1,
            fields: s1.fields.map((f) => {
              if (f.id === e.id) {
                return {
                  ...f,
                  options: [...f.options, { label: "Label", value: "Value" }],
                };
              }
              return f;
            }),
          };
        }
        return s1;
      })
    );
  };
  const renderOption = (s: FormSection, e: FormField) => {
    if (
      e.type == FormFieldType.Checkbox ||
      e.type == FormFieldType.RadioButton ||
      e.type == FormFieldType.Dropdown
    )
      return (
        <div className="mb-4">
          {e.options.map((o, i) => (
            <div
              className=" justify-between flex flex-row items-center mb-2"
              key={`${e.id}-${i}`}
            >
              <div className="grid grid-cols-2 gap-2 flex-1">
                <input
                  value={o.label}
                  className="px-2 py-1 text-sm rounded-lg border bg-transparent"
                  placeholder="Label"
                  onChange={(val) => {
                    updateOption(s, e, i, "label", val.target.value);
                  }}
                  onBlur={() => {
                    saveForm({
                      ...form!,
                      sections,
                    });
                  }}
                />
                <input
                  value={o.value}
                  className="px-2 py-1 text-sm rounded-lg border bg-transparent"
                  placeholder="Value"
                  onChange={(val) => {
                    updateOption(s, e, i, "value", val.target.value);
                  }}
                  onBlur={() => {
                    saveForm({
                      ...form!,
                      sections,
                    });
                  }}
                />
              </div>
              <HiXMark
                className="w-4 h-4 cursor-pointer  hover:text-red-400"
                onClick={() => removeOption(s, e, i)}
              />
            </div>
          ))}
          <p
            className="text-sm hover:font-[500] cursor-pointer"
            onClick={() => addOption(s, e)}
          >
            + Option
          </p>
        </div>
      );
    return null;
  };
  const renderDefaultValue = (s: FormSection, e: FormField) => {
    if (
      e.type == FormFieldType.TextArea ||
      e.type == FormFieldType.TextField ||
      e.type == FormFieldType.EmailField ||
      e.type == FormFieldType.Dropdown ||
      e.type == FormFieldType.NumberField
    )
      return (
        <input
          disabled={isDisabled}
          type="text"
          placeholder="Default Value"
          className="px-2 py-1 text-sm rounded-lg border bg-transparent"
          value={e.default_value ?? ""}
          onChange={(val) => {
            updateField(s, e, "default_value", val.target.value);
          }}
          onBlur={() => {
            saveForm({
              ...form!,
              sections,
            });
          }}
        />
      );
    return null;
  };
  const renderPlaceHolder = (s: FormSection, e: FormField) => {
    switch (e.type) {
      case FormFieldType.TextField:
      case FormFieldType.TextArea:
      case FormFieldType.EmailField:
      case FormFieldType.Dropdown:
        return (
          <input
            disabled={isDisabled}
            type="text"
            placeholder="Placeholder"
            className="px-2 py-1 text-sm rounded-lg border bg-transparent"
            value={e.placeholder ?? ""}
            onChange={(val) => {
              updateField(s, e, "placeholder", val.target.value);
            }}
            onBlur={() => {
              saveForm({
                ...form!,
                sections,
              });
            }}
          />
        );
      case FormFieldType.NumberField:
        return (
          <input
            disabled={isDisabled}
            type="number"
            placeholder="Placeholder"
            className="px-2 py-1 text-sm rounded-lg border bg-transparent"
            value={e.placeholder ?? ""}
            onChange={(val) => {
              updateField(s, e, "placeholder", parseInt(val.target.value));
            }}
            onBlur={() => {
              saveForm({
                ...form!,
                sections,
              });
            }}
          />
        );
      default:
        return null;
    }
  };
  const addFieldMenu = (e: FormSection) => (
    <div className="group/edit invisible  group-hover/item:visible  cursor-pointer mx-2">
      <Dropdown
        className="custom-menu"
        title={
          !isDisabled && (
            <PiPlusCircleBold
              className=" text-gray-600 hover:text-green-400"
              size={20}
            />
          )
        }
        noCaret
        placement="bottomEnd"
        trigger={"click"}
      >
        <Dropdown.Item onClick={() => addField(e, FormFieldType.TextField)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.TextField, 12)} Text
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => addField(e, FormFieldType.TextArea)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.TextArea, 12)}
            Text Area
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => addField(e, FormFieldType.RadioButton)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.RadioButton, 12)}
            Radio Button
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => addField(e, FormFieldType.Checkbox)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.Checkbox, 12)}
            Checkbox
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => addField(e, FormFieldType.Dropdown)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.Dropdown, 12)}
            Dropdown
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => addField(e, FormFieldType.DatePicker)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.DatePicker, 12)}
            Date Picker
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => addField(e, FormFieldType.DateRangePicker)}
        >
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.DateRangePicker, 12)}
            Date Range Picker
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => addField(e, FormFieldType.NumberField)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.NumberField, 12)}
            Number Picker
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => addField(e, FormFieldType.EmailField)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.EmailField, 12)}
            Email Field
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => addField(e, FormFieldType.PasswordField)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.PasswordField, 12)}
            Password Field
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => addField(e, FormFieldType.FileUpload)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.FileUpload, 12)}
            File Upload
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => addField(e, FormFieldType.ToggleSwitch)}>
          <div className="flex flex-row items-center gap-2">
            {renderIcon(FormFieldType.ToggleSwitch, 12)}
            Toggle Switch
          </div>
        </Dropdown.Item>
      </Dropdown>
    </div>
  );

  const renderField = (s: FormSection, e: FormField, index: number) => {
    return (
      <Draggable draggableId={e.id} index={index} key={e.id}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`group/item  bg-gray-50 rounded-lg p-4 hover:border ${
              snapshot.isDragging ? "dragging" : ""
            } mb-2`}
          >
            <div className="flex flex-row gap-2 ">
              <div className="py-1">{renderIcon(e.type, 24)}</div>
              <div className="flex flex-col flex-1">
                <input
                  disabled={isDisabled}
                  onChange={(val) => {
                    updateField(s, e, "label", val.target.value);
                  }}
                  value={e.label ?? ""}
                  className="focus:ring-0 focus:outline-none focus:ring-gray-200  text-lg w-full bg-transparent"
                  onBlur={() => {
                    saveForm({
                      ...form!,
                      sections,
                    });
                  }}
                ></input>
                <div className="grid grid-cols-3 gap-2 mb-2">
                  {renderPlaceHolder(s, e)}

                  {renderDefaultValue(s, e)}
                  <input
                    disabled={isDisabled}
                    type="text"
                    placeholder="Help Text"
                    className="px-2 py-1 text-sm rounded-lg border bg-transparent"
                    value={e.help_text ?? ""}
                    onChange={(val) => {
                      updateField(s, e, "help_text", val.target.value);
                    }}
                    onBlur={() => {
                      saveForm({
                        ...form!,
                        sections,
                      });
                    }}
                  />
                </div>
                {renderOption(s, e)}
                <div className="grid grid-cols-3 gap-2 mb-2">
                  <Toggle
                    disabled={isDisabled}
                    checked={!e.disabled}
                    onChange={(val) => {
                      updateField(s, e, "disabled", !val);
                    }}
                    onBlur={() => {
                      saveForm({
                        ...form!,
                        sections,
                      });
                    }}
                  >
                    Enable
                  </Toggle>
                  <Toggle
                    disabled={isDisabled}
                    checked={e.required}
                    onChange={(val) => {
                      updateField(s, e, "required", val);
                    }}
                    onBlur={() => {
                      saveForm({
                        ...form!,
                        sections,
                      });
                    }}
                  >
                    Required
                  </Toggle>
                  {/* {e.type == FormFieldType.Dropdown && (
                    <Toggle
                      checked={e.is_multi}
                      onChange={(val) => {
                        updateField(s, e, "is_multi", val);
                      }}
                      onBlur={() => {
                        saveForm({
                          ...form!,
                          sections,
                        });
                      }}
                    >
                      Multiple Choice
                    </Toggle>
                  )} */}
                </div>
              </div>
              {!isDisabled && (
                <PiTrash
                  className="cursor-pointer group/edit invisible  group-hover/item:visible mr-2 hover:text-red-400 "
                  onClick={() => {
                    let newSections: FormSection[] = [
                      ...sections.filter((s1) => {
                        if (s1.id == s.id) {
                          s.fields = s.fields.filter((f) => f.id != e.id);
                          return s;
                        }
                        return s1;
                      }),
                    ];
                    setSections(newSections);

                    saveForm({
                      ...form!,
                      sections: newSections,
                    });
                  }}
                />
              )}
            </div>
          </div>
        )}
      </Draggable>
    );
  };
  const onDragEnd = (result: DropResult) => {
    if (isDisabled) return;
    const { source, destination, type } = result;

    if (!destination) return;
    if (type === "CATEGORY") {
      const newColumns = [...sections];
      const [movedColumn] = newColumns.splice(source.index, 1);
      newColumns.splice(destination.index, 0, movedColumn);
      setSections(newColumns);
      saveForm({
        ...form!,
        sections: newColumns,
      });
    }
    if (type === "ITEM") {
      const newColumns = [...sections];
      const sourceColIndex = newColumns.findIndex(
        (col) => col.id === source.droppableId
      );
      const destColIndex = newColumns.findIndex(
        (col) => col.id === destination.droppableId
      );

      const sourceCol = newColumns[sourceColIndex];
      const destCol = newColumns[destColIndex];

      let sourceFields = [...sourceCol.fields];
      let destFields =
        sourceColIndex === destColIndex ? sourceFields : [...destCol.fields];

      const [removed] = sourceFields.splice(source.index, 1);
      //   removed.id = sections.find((e) => e.id == destination.droppableId)?.id!;

      destFields.splice(destination.index, 0, removed);
      //   if (sourceColIndex == destColIndex) {
      //     let newSections: FormSection[] = [
      //       ...sections.map((s1) => {
      //         if (s1.id == sourceCol.id) {
      //           s1.fields = destFields;
      //         }
      //         return s1;
      //       }),
      //     ];

      //     setSections(newSections);
      //   } else {
      //     let newSections: FormSection[] = [
      //         ...sections.map((s1) => {
      //           if (s1.id == destCol.id) {
      //             s1.fields = destFields;
      //           }
      //           return s1;
      //         }),
      //       ];

      //       setSections(newSections);
      //   }

      newColumns[sourceColIndex] = {
        ...sourceCol,
        fields: sourceFields,
      };

      if (sourceColIndex !== destColIndex) {
        newColumns[destColIndex] = {
          ...destCol,
          fields: destFields,
        };
      }

      //   console.log(newColumns);
      setSections(newColumns);
      saveForm({
        ...form!,
        sections: newColumns,
      });
    }
  };
  const renderForm = () => (
    <div
      className=" col-span-9 h-full border-l p-4  overflow-y-auto bg-gray-100 flex flex-col justify-center items-center py-8"
      style={{}}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <div
          className="w-[75%] bg-white h-full rounded-xl hover:shadow-lg  flex-col flex"
          style={{
            borderTopColor: color,
            borderTopWidth: 12,
          }}
        >
          <div className="flex flex-row justify-end items-center py-2 px-4 space-x-4">
            <PiShare
              size={16}
              className="text-gray-300 hover:text-gray-600 cursor-pointer"
              onClick={() => {
                // nav(`/form/${form?.code}`)
                window.open(
                  process.env.REACT_APP_BASE_URL + `/form/${form?.code}`
                );
              }}
            />
            <FaCode
              size={16}
              className="text-gray-300 hover:text-gray-600 cursor-pointer"
              onClick={() => {
                const embedCode = `
                <iframe src="${process.env.REACT_APP_BASE_URL}/form/${form?.code}" width="100%" style="height: 100vh;"  frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>
                <script>
                  window.addEventListener('message', (e) => {
                    if (JSON.parse(e.data).type === 'FORM_SUBMITTED') {
                      console.log(JSON.parse(e.data).data);
                      alert('Form submitted successfully!');
                    }
                  });
                </script>
                `;
                // console.log(embedCode);
                setEmbedFormCode(embedCode);
                setModalEmbedCode(true);
              }}
            />
          </div>
          <div className="flex flex-row justify-between w-full border-b px-8 h-16">
            <div className=" flex flex-row">
              <div
                className={`p-4  border-b-4 cursor-pointer hover:border-b-[#e53969]`}
                style={{
                  borderBottom:
                    mode == "builder"
                      ? `2px solid ${color}`
                      : "2px solid #dedede",
                }}
                onClick={() => {
                  setMode("builder");
                  getDetail();
                }}
              >
                Builder
              </div>
              <div
                className={`p-4  border-b-4 cursor-pointer hover:border-b-[#e53969]`}
                style={{
                  borderBottom:
                    mode == "preview"
                      ? `2px solid ${color}`
                      : "2px solid #dedede",
                }}
                onClick={() => {
                  setMode("preview");
                  getDetail();
                }}
              >
                Preview
              </div>
              <div
                className={`p-4  border-b-4 cursor-pointer hover:border-b-[#e53969]`}
                style={{
                  borderBottom:
                    mode == "response"
                      ? `2px solid ${color}`
                      : "2px solid #dedede",
                }}
                onClick={() => {
                  setMode("response");
                  getDetail();
                }}
              >
                Response
              </div>
            </div>
            <div className="py-4">
              {mode == "builder" && !isDisabled && (
                <Button
                  size="sm"
                  appearance="ghost"
                  onClick={() => {
                    let data = [
                      ...sections,
                      {
                        id: randomStr(20),
                        section_title: "New Section",
                        description: "",
                        fields: [],
                      },
                    ];
                    setSections(data);

                    saveForm({
                      ...form!,
                      sections: data,
                    });
                    setTimeout(() => {
                      scrollToBottom();
                    }, 500);
                  }}
                >
                  + Add Section
                </Button>
              )}
            </div>
          </div>

          {mode == "builder" && renderBuilder()}
          {mode == "preview" && form && (
            <div className="p-6 overflow-y-auto">
              {" "}
              <FormPreview form={form!} showSubmit={false} />
            </div>
          )}
          {mode == "response" && form && (
            <div className="p-6 overflow-y-auto">
              <FormResponsePage form={form!} />
            </div>
          )}
        </div>
      </DragDropContext>
    </div>
  );

  const renderBuilder = () => (
    <div className="px-8 py-4 flex-1 overflow-y-auto">
      <StrictModeDroppable droppableId="all-columns" type="CATEGORY">
        {(provided) => (
          <div
            className=" "
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {sections.map((e, index) => (
              <Draggable key={e.id} draggableId={e.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className="py-4 border-b flex flex-col relative group/item mb-4 bg-white"
                  >
                    <div className="group/edit invisible  group-hover/item:visible absolute top-2 right-2 cursor-pointer">
                      {!isDisabled && (
                        <PiTrash
                          className=" hover:text-red-400 cursor-pointer"
                          size={24}
                          onClick={() => {
                            let newSections: FormSection[] = [
                              ...sections.filter((s1) => {
                                if (s1.id == e.id) {
                                  return false;
                                }
                                return s1;
                              }),
                            ];
                            setSections(newSections);
                            saveForm({
                              ...form!,
                              sections: newSections,
                            });
                          }}
                        />
                      )}
                    </div>
                    <div
                      className="text-gray-400 italic group/edit invisible  group-hover/item:visible"
                      {...provided.dragHandleProps}
                    >
                      <IoMove />
                    </div>
                    <div className="flex flex-row justify-between items-center">
                      <input
                        type="text"
                        value={e.section_title ?? ""}
                        onChange={(val) => {
                          setSections([
                            ...sections.map((s) => {
                              if (e.id == s.id) {
                                s.section_title = val.target.value;
                              }
                              return s;
                            }),
                          ]);
                        }}
                        className="focus:ring-0 focus:outline-none focus:ring-gray-200  text-xl"
                        onBlur={() => {
                          saveForm({
                            ...form!,
                            sections,
                          });
                        }}
                      />
                      {addFieldMenu(e)}
                    </div>

                    <textarea
                      placeholder="write description"
                      value={e.description ?? ""}
                      onChange={(val) => {
                        setSections([
                          ...sections.map((s) => {
                            if (e.id == s.id) {
                              s.description = val.target.value;
                            }
                            return s;
                          }),
                        ]);
                      }}
                      onBlur={() => {
                        saveForm({
                          ...form!,
                          sections,
                        });
                      }}
                      className="focus:ring-0 focus:outline-none focus:ring-gray-200 text-gray-500 mb-2"
                    />
                    {!snapshot.isDragging && (
                      <ul className="space-y-4">
                        <StrictModeDroppable droppableId={e.id} type="ITEM">
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              className="task-list min-h-[32px] h-full] px-4 overflow-y-auto"
                            >
                              {e.fields.map((f, i) => (
                                <li className="" key={f.id}>
                                  {renderField(e, f, i)}
                                </li>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </StrictModeDroppable>
                      </ul>
                    )}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            <div ref={messagesEndRef} className="p-4 bg-white" />
          </div>
        )}
      </StrictModeDroppable>
    </div>
  );
  const renderDetail = () => (
    <div className=" col-span-3 h-full flex-col flex overflow-y-auto ">
      <div className="flex  flex-row justify-between items-center  w-full ">
        <div
          className="h-32 w-full rounded-tl-[15px] relative"
          style={{
            backgroundColor: color,
            backgroundImage: form?.cover_url && `url(${form?.cover_url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          {form?.picture_url && (
            <img
              src={form?.picture_url}
              className={`w-[64px] h-[64px] border-2 border-white rounded-full object-cover absolute top-[70%] left-[12%] transform -translate-x-1/2 -translate-y-1/2`}
            />
          )}
        </div>
      </div>
      <div className="border-t"></div>
      <div className="flex-1 h-full overflow-y-auto ">
        <div className=" border-b">
          <div className=" p-4">
            <input
              type="text"
              value={form?.title}
              onChange={(e) =>
                setForm((prev) => ({
                  ...prev!,
                  title: e.target.value,
                }))
              }
              className="font-bold text-xl w-full outline-none "
            />
            <textarea
              value={form?.description}
              onChange={(e) =>
                setForm((prev) => ({
                  ...prev!,
                  description: e.target.value,
                }))
              }
              className="w-full outline-none  resize-none"
            />
          </div>
        </div>
        <div className="border-b">
          <div className=" p-4">
            <h3 className="font-[500] text-xl mb-2">Target</h3>
            <div className="flex flex-col space-y-4">
              <div>
                <h4 className="font-[400] text-base">Project</h4>
                <SelectPicker
                  block
                  disabled={form?.status == "PUBLISH"}
                  data={projects}
                  labelKey="title"
                  valueKey="id"
                  value={selectedProjectID}
                  onChange={(val) => {
                    setSelectedProjectID(val!);
                    setSelectedProject(projects.find((p) => p.id == val)!);
                  }}
                  onSearch={getAllProjects}
                  placeholder="Select Project"
                />
              </div>
              <div>
                <h4 className="font-[400] text-base">Board</h4>
                <SelectPicker
                  block
                  disabled={form?.status == "PUBLISH"}
                  data={selectedProject?.boards ?? []}
                  labelKey="title"
                  valueKey="id"
                  value={selectedBoardID}
                  onChange={(val) => {
                    setSelectedBoardID(val!);
                    getBoard(val!)
                      .then((res) => res.json())
                      .then((res) => {
                        setSelectedBoard(res.data);
                      });
                  }}
                  onSearch={getAllProjects}
                  placeholder="Select Board"
                />
              </div>
              <div>
                <h4 className="font-[400] text-base">Group</h4>
                <SelectPicker
                  block
                  disabled={form?.status == "PUBLISH"}
                  data={selectedBoard?.columns ?? []}
                  labelKey="title"
                  valueKey="id"
                  value={selectedColumnID}
                  onChange={(val) => {
                    setSelectedColumnID(val!);
                    setSelectedColumn(
                      selectedBoard?.columns.find((b) => b.id == val)!
                    );
                  }}
                  onSearch={getAllProjects}
                  placeholder="Select Group"
                />
              </div>
              <div>
                <h4 className="font-[400] text-base">Public</h4>
                <Toggle
                  disabled={isDisabled}
                  checked={form?.is_public}
                  onChange={(val) =>
                    setForm((prev) => ({ ...prev!, is_public: val }))
                  }
                />
              </div>
              <div>
                <h4 className="font-[400] text-base">Status</h4>
                <SelectPicker
                  block
                  searchable={false}
                  disabled={form?.status == "PUBLISH"}
                  data={[
                    { label: "DRAFT", value: "DRAFT" },
                    { label: "PUBLISH", value: "PUBLISH" },
                    { label: "DISABLED", value: "DISABLED" },
                  ]}
                  value={form?.status ?? ""}
                  onChange={(val) => {
                    setForm({
                      ...form!,
                      status: val! as "DRAFT" | "PUBLISH" | "DISABLED",
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="border-b">
          <div className=" p-4">
            <h3 className="font-[500] text-xl mb-2">Webhook</h3>
            <div className="flex flex-col space-y-4">
              <div>
                <h4 className="font-[400] text-base">Submit URL</h4>
                <input
                  disabled={form?.status == "PUBLISH"}
                  type="text"
                  name="submit_url"
                  placeholder="Submit Url"
                  style={{ width: "100%" }}
                  className="mt-2 w-full border border-gray-300 rounded-lg p-2"
                  value={form?.submit_url ?? ""}
                  onChange={(val) => {
                    setForm({ ...form!, submit_url: val.target.value });
                  }}
                />
              </div>
              <div>
                <h4 className="font-[400] text-base">Method</h4>
                <SelectPicker
                  disabled={form?.status == "PUBLISH"}
                  block
                  searchable={false}
                  data={[
                    { label: "GET", value: "GET" },
                    { label: "POST", value: "POST" },
                    { label: "PUT", value: "PUT" },
                  ]}
                  value={form?.method ?? ""}
                  onChange={(val) => {
                    setForm({ ...form!, method: val! });
                  }}
                />
              </div>
              <div>
                <h4 className="font-[400] text-base">Headers</h4>
                <textarea
                  disabled={form?.status == "PUBLISH"}
                  name="headers"
                  placeholder="headers"
                  style={{ width: "100%" }}
                  className="mt-2 w-full border border-gray-300 rounded-lg p-2"
                  value={form?.headers ?? ""}
                  onChange={(val) => {
                    setForm({ ...form!, headers: val.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex p-4">
            <Button
              className="w-full"
              appearance="primary"
              color="green"
              onClick={() => {
                setIsLoading(true);
                updateForm(form!.id, {
                  ...form,
                  sections: sections,
                })
                  .then((res) => {
                    successToast("Form update successfully");
                  })
                  .catch((err) => {
                    errorToast(err);
                  })
                  .finally(() => setIsLoading(false));
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <MainLayout onLoadProfile={setProfile}>
      <div
        className="flex flex-col flex-1 overflow-x-auto  h-full"
        style={{ backgroundColor: color }}
      >
        <div
          className="  h-full bg-white ml-4 rounded-tl-[15px]  flex flex-col"
          style={{ height: "calc(100vh - 50px)" }}
        >
          <div className=" grid grid-cols-12  h-full">
            {renderDetail()}
            {renderForm()}
          </div>
        </div>
      </div>
      <Modal open={modalEmbedCode} onClose={() => setModalEmbedCode(false)}>
        <Modal.Header>
          <Modal.Title>Embed Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <code>{embedFormCode}</code>
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance="primary"
            onClick={() => {
              navigator.clipboard.writeText(embedFormCode);
              setModalEmbedCode(false);
              successToast("embed code copied")
            }}
          >
            Copy Code
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};
export default FormDetail;
