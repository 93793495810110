import type { FC } from "react";
import { Modal } from "rsuite";
import { RapidApiData } from "../model/rapid_api_data";
import { IoReloadOutline, IoShareSocialOutline } from "react-icons/io5";
import { LuLink } from "react-icons/lu";
import { money, nl2br } from "../utils/helper";
import { TfiComment } from "react-icons/tfi";
import { BsQuote, BsYoutube } from "react-icons/bs";
import { AiOutlineLike, AiOutlineRetweet } from "react-icons/ai";
import { BiCollection } from "react-icons/bi";
import { PiBookmark, PiPlay } from "react-icons/pi";
import { FaDigg } from "react-icons/fa6";

interface ModelRapidApiDataProps {
  rapidApiData: RapidApiData | undefined;
  gettingData: boolean;
  onClose: () => void;
  onRefresh?: () => void;
}

const ModelRapidApiData: FC<ModelRapidApiDataProps> = ({
  rapidApiData,
  gettingData,
  onClose,
  onRefresh,
}) => {
  return (
    <Modal size={"lg"} open={rapidApiData != undefined} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>{rapidApiData?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="grid grid-cols-2 gap-4">
          <div>
            {rapidApiData?.thumbnail_url && (
              <img
                src={rapidApiData?.thumbnail_url}
                className="  object-cover w-full rounded-lg"
              />
            )}
          </div>
          <div>
            <div className="flex justify-between">
              <h3 className="text-xl font[500] mb-2">Insight</h3>
              {onRefresh && (
                <IoReloadOutline
                  size={20}
                  onClick={() => {
                    if (onRefresh) onRefresh();
                  }}
                  className={`${
                    gettingData ? "animate-spin" : ""
                  } cursor-pointer`}
                />
              )}
            </div>
            <table className="w-full">
              <tbody>
                {rapidApiData?.params[0].value && (
                  <tr>
                    <td className="pr-2">Link</td>
                    <td className="text-right"></td>
                    <td className="w-1">
                      <LuLink
                        size={12}
                        className=" text-blue-400 hover:text-blue-600 cursor-pointer"
                        onClick={() => {
                          if (
                            rapidApiData?.data.endpoint_key == "video_details"
                          ) {
                            window.open(
                              `https://www.youtube.com/watch?v=${rapidApiData?.data?.id}`
                            );
                            // window.open(rapidApiData?.params[0].value);
                          }
                          if (
                            rapidApiData?.data.endpoint_key ==
                            "get_tweet_details"
                          ) {
                            window.open(
                              `https://x.com/${rapidApiData?.data.user?.screen_name}/status/${rapidApiData?.data.id_str}`
                            );
                            // window.open(rapidApiData?.params[0].value);
                          }
                          if (
                            rapidApiData?.data.endpoint_key ==
                            "get_facebook_post_details"
                          ) {
                            window.open(rapidApiData?.data.post_link);
                            // window.open(rapidApiData?.params[0].value);
                          }
                          if (
                            rapidApiData?.data.endpoint_key == "get_post_detail"
                          ) {
                            window.open(
                              `https://www.tiktok.com/@${rapidApiData?.data.author.uniqueId}/video/${rapidApiData?.data.id}?is_from_webapp=1&sender_device=pc`
                            );
                            // window.open(rapidApiData?.params[0].value);
                          }

                          if (
                            rapidApiData?.data.endpoint_key ==
                            "media_info_by_url_v2_media_info_by_url_get"
                          ) {
                            window.open(rapidApiData?.params[0].value);
                          }
                        }}
                      />
                    </td>
                  </tr>
                )}
                {rapidApiData?.data?.reactions && (
                  <tr>
                    <td className="pr-2">Reactions</td>
                    <td className="text-right"></td>
                    <td className="w-1"></td>
                  </tr>
                )}
                {rapidApiData?.data?.reactions?.Angry && (
                  <tr>
                    <td className="pr-2">Angry</td>
                    <td className="text-right">
                      {money(rapidApiData?.data?.reactions?.Angry)}
                    </td>
                    <td className="w-1"></td>
                  </tr>
                )}
                {rapidApiData?.data?.reactions?.Care && (
                  <tr>
                    <td className="pr-2">Care</td>
                    <td className="text-right">
                      {money(rapidApiData?.data?.reactions?.Care)}
                    </td>
                    <td className="w-1"></td>
                  </tr>
                )}
                {rapidApiData?.data?.reactions?.Haha && (
                  <tr>
                    <td className="pr-2">Haha</td>
                    <td className="text-right">
                      {money(rapidApiData?.data?.reactions?.Haha)}
                    </td>
                    <td className="w-1"></td>
                  </tr>
                )}
                {rapidApiData?.data?.reactions?.Love && (
                  <tr>
                    <td className="pr-2">Love</td>
                    <td className="text-right">
                      {money(rapidApiData?.data?.reactions?.Love)}
                    </td>
                    <td className="w-1"></td>
                  </tr>
                )}
                {rapidApiData?.data?.reactions?.Sad && (
                  <tr>
                    <td className="pr-2">Sad</td>
                    <td className="text-right">
                      {money(rapidApiData?.data?.reactions?.Sad)}
                    </td>
                    <td className="w-1"></td>
                  </tr>
                )}
                {rapidApiData?.data?.reactions?.Wow && (
                  <tr>
                    <td className="pr-2">Wow</td>
                    <td className="text-right">
                      {money(rapidApiData?.data?.reactions?.Wow)}
                    </td>
                    <td className="w-1"></td>
                  </tr>
                )}
                {rapidApiData?.data?.reactions?.total_reaction_count && (
                  <tr>
                    <td className="pr-2">Total Reaction Count</td>
                    <td className="text-right">
                      {money(
                        rapidApiData?.data?.reactions?.total_reaction_count
                      )}
                    </td>
                    <td className="w-1"></td>
                  </tr>
                )}
                {rapidApiData?.data?.comments_count && (
                  <tr>
                    <td className="pr-2">Comments</td>
                    <td className="text-right">
                      {money(rapidApiData?.data?.comments_count)}
                    </td>
                    <td className="w-1">
                      <TfiComment size={12} />
                    </td>
                  </tr>
                )}
                {rapidApiData?.data?.viewCount && (
                  <tr>
                    <td className="pr-2">Views</td>
                    <td className="text-right">
                      {money(rapidApiData?.data?.viewCount)}
                    </td>
                    <td className="w-1">
                      <BsYoutube size={12} />
                    </td>
                  </tr>
                )}
                {rapidApiData?.data?.likeCount && (
                  <tr>
                    <td className="pr-2">Likes</td>
                    <td className="text-right">
                      {money(rapidApiData?.data?.likeCount)}
                    </td>
                    <td className="w-1">
                      <AiOutlineLike size={12} />
                    </td>
                  </tr>
                )}
                {rapidApiData?.data?.commentCount && (
                  <tr>
                    <td className="pr-2">Comments</td>
                    <td className="text-right">
                      {money(rapidApiData?.data?.commentCount)}
                    </td>
                    <td className="w-1">
                      <TfiComment size={12} />
                    </td>
                  </tr>
                )}
                {rapidApiData?.data?.share_count && (
                  <tr>
                    <td className="pr-2">Share Count</td>
                    <td className="text-right">
                      {money(rapidApiData?.data?.share_count)}
                    </td>
                    <td className="w-1"></td>
                  </tr>
                )}
                {rapidApiData?.data?.stats?.collectCount && (
                  <tr>
                    <td className="pr-2">Collects</td>
                    <td className="text-right">
                      {money(rapidApiData?.data?.stats?.collectCount)}
                    </td>
                    <td className="w-1">
                      <BiCollection size={12} />
                    </td>
                  </tr>
                )}
                {rapidApiData?.data?.stats?.commentCount && (
                  <tr>
                    <td className="pr-2">Comments</td>
                    <td className="text-right">
                      {money(rapidApiData?.data?.stats?.commentCount)}
                    </td>
                    <td className="w-1">
                      <TfiComment size={12} />
                    </td>
                  </tr>
                )}
                {rapidApiData?.data?.stats?.playCount && (
                  <tr>
                    <td className="pr-2">Plays</td>
                    <td className="text-right">
                      {money(rapidApiData?.data?.stats?.playCount)}
                    </td>
                    <td className="w-1">
                      <PiPlay size={12} />
                    </td>
                  </tr>
                )}
                {rapidApiData?.data?.stats?.shareCount && (
                  <tr>
                    <td className="pr-2">Shares</td>
                    <td className="text-right">
                      {money(rapidApiData?.data?.stats?.shareCount)}
                    </td>
                    <td className="w-1">
                      <IoShareSocialOutline size={12} />
                    </td>
                  </tr>
                )}
                {rapidApiData?.data?.stats?.diggCount && (
                  <tr>
                    <td className="pr-2">Diggs</td>
                    <td className="text-right">
                      {money(rapidApiData?.data?.stats?.diggCount)}
                    </td>
                    <td className="w-1">
                      <FaDigg size={12} />
                    </td>
                  </tr>
                )}
                {rapidApiData?.data.favorite_count && (
                  <tr>
                    <td className="pr-2">Favorites</td>
                    <td className="text-right">
                      {money(rapidApiData?.data.favorite_count)}
                    </td>
                    <td className="w-1">
                      <AiOutlineLike size={12} />
                    </td>
                  </tr>
                )}
                {rapidApiData?.data.like_count && (
                  <tr>
                    <td className="pr-2">Likes</td>
                    <td className="text-right">
                      {money(rapidApiData?.data.like_count)}
                    </td>
                    <td className="w-1">
                      <AiOutlineLike size={12} />
                    </td>
                  </tr>
                )}

                {rapidApiData?.data.reply_count && (
                  <tr>
                    <td className="pr-2">Replies</td>
                    <td className="text-right">
                      {money(rapidApiData?.data.reply_count)}
                    </td>
                    <td className="w-1">
                      <TfiComment size={12} />
                    </td>
                  </tr>
                )}
                {rapidApiData?.data.comment_count && (
                  <tr>
                    <td className="pr-2">Comments</td>
                    <td className="text-right">
                      {money(rapidApiData?.data.comment_count)}
                    </td>
                    <td className="w-1">
                      <TfiComment size={12} />
                    </td>
                  </tr>
                )}
                {rapidApiData?.data.play_count && (
                  <tr>
                    <td className="pr-2">Plays</td>
                    <td className="text-right">
                      {money(rapidApiData?.data.play_count)}
                    </td>
                    <td className="w-1">
                      <PiPlay size={12} />
                    </td>
                  </tr>
                )}
                {rapidApiData?.data.retweet_count && (
                  <tr>
                    <td className="pr-2">Retweets</td>
                    <td className="text-right">
                      {money(rapidApiData?.data.retweet_count)}
                    </td>
                    <td className="w-1">
                      <AiOutlineRetweet size={12} />
                    </td>
                  </tr>
                )}
                {rapidApiData?.data.quote_count && (
                  <tr>
                    <td className="pr-2">Quotes</td>
                    <td className="text-right">
                      {money(rapidApiData?.data.quote_count)}
                    </td>
                    <td className="w-1">
                      <BsQuote size={12} />
                    </td>
                  </tr>
                )}
                {rapidApiData?.data.save_count && (
                  <tr>
                    <td className="pr-2">Saves</td>
                    <td className="text-right">
                      {money(rapidApiData?.data.save_count)}
                    </td>
                    <td className="w-1">
                      <PiBookmark size={12} />
                    </td>
                  </tr>
                )}
                {rapidApiData?.data.reshare_count && (
                  <tr>
                    <td className="pr-2">Reshares</td>
                    <td className="text-right">
                      {money(rapidApiData?.data.reshare_count)}
                    </td>
                    <td className="w-1">
                      <IoShareSocialOutline size={12} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {rapidApiData?.data.caption && (
              <div
                className="text-xs mt-4"
                dangerouslySetInnerHTML={{
                  __html: nl2br(rapidApiData?.data.caption.text),
                }}
              />
            )}
            {rapidApiData?.data.full_text && (
              <div
                className="text-xs mt-4"
                dangerouslySetInnerHTML={{
                  __html: nl2br(rapidApiData?.data.full_text),
                }}
              />
            )}
            {rapidApiData?.data.desc && (
              <div
                className="text-xs mt-4"
                dangerouslySetInnerHTML={{
                  __html: nl2br(rapidApiData?.data.desc),
                }}
              />
            )}
            {rapidApiData?.data.values?.text && (
              <div
                className="text-xs mt-4"
                dangerouslySetInnerHTML={{
                  __html: nl2br(rapidApiData?.data.values?.text),
                }}
              />
            )}
            {rapidApiData?.data.title && (
              <div
                className="text-sm font-[500] mt-4 "
                dangerouslySetInnerHTML={{
                  __html: nl2br(rapidApiData?.data.title),
                }}
              />
            )}
            {rapidApiData?.data?.description && (
              <div
                className="text-xs mt-4"
                dangerouslySetInnerHTML={{
                  __html: nl2br(rapidApiData?.data.description),
                }}
              />
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ModelRapidApiData;
