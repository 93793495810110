import type { FC } from "react";
import { useContext, useEffect, useRef, useState } from "react";
import MainLayout from "../components/MainLayout";
import { User } from "../model/user";
import { BgColorContext } from "../objects/color_object";
import { ProjectContext } from "../objects/project_object";
import { Company } from "../model/company";
import { Link, useParams } from "react-router-dom";
import { LoadingContext } from "../objects/loading";
import { errorToast, successToast } from "../utils/helper-ui";
import {
  addRapidPlugin,
  getCompany,
  removeRapidPlugin,
  updateCompany,
} from "../repositories/company";
import { CiCamera } from "react-icons/ci";
import { uploadFile } from "../repositories/file";
import { Avatar, Button, Divider, Input, Modal, SelectPicker } from "rsuite";
import { copyToClipboard, initials } from "../utils/helper";
import { RiUserAddLine } from "react-icons/ri";
import { BsPuzzle } from "react-icons/bs";
import {
  PiKeyLight,
  PiPencilLight,
  PiPuzzlePieceBold,
  PiTrashLight,
} from "react-icons/pi";
import { getRapidApiPlugins } from "../repositories/rapid_api_plugin";
import { RapidApiPlugin } from "../model/rapid_api_plugin";
import InlineForm from "../components/InlineForm";
import Swal from "sweetalert2";
import { CompanyContext } from "../objects/company_context";

interface CompanyDetailProps {}

const CompanyDetail: FC<CompanyDetailProps> = ({}) => {
  const { project: activeProject, setProject: setActiveProject } =
    useContext(ProjectContext);
  const [profile, setProfile] = useState<User>();
  const { color, setColor } = useContext(BgColorContext);
  const { companyId } = useParams();
  const {company, setCompany} = useContext(CompanyContext);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);
  const [permissions, setPermissions] = useState<string[]>([]);
  const fileRef = useRef<HTMLInputElement>(null);
  const fileRef2 = useRef<HTMLInputElement>(null);
  const [logoPic, setLogoPic] = useState("");
  const [logoPicUrl, setLogoPicUrl] = useState("");
  const [coverPic, setCoverPic] = useState("");
  const [coverPicUrl, setCoverPicUrl] = useState("");
  const [geminiInstruction, setGeminiInstruction] = useState("");
  const [geminiModels, setGeminiModels] = useState<string[]>([
    "gemini-1.5-flash",
    "gemini-1.5-pro",
    "gemini-2.0-flash-exp",
  ]);
  const [geminiAPIKey, setGeminiAPIKey] = useState("");
  const [modalRapidApi, setModalRapidApi] = useState(false);
  const [plugins, setPlugins] = useState<RapidApiPlugin[]>([]);
  const [rapidApiKey, setRapidApiKey] = useState("");
  const [rapidApiHost, setRapidApiHost] = useState("");
  const [selectedPlugin, setSelectedPlugin] = useState("");

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    getCompanyDetail();
  }, [companyId, mounted]);

  const getCompanyDetail = async () => {
    if (!mounted) return;
    try {
      setIsLoading(true);
      const res = await getCompany(companyId!);
      const resJson = await res.json();
      setCompany(resJson.data);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setLogoPic(company?.logo ?? "");
    setLogoPicUrl(company?.logo_url ?? "");
    setCoverPic(company?.cover ?? "");
    setCoverPicUrl(company?.cover_url ?? "");
    getRapidApiPlugins({ page: 1, limit: 0, search: "" })
      .then((res) => res.json())
      .then((resJson) => {
        setPlugins(resJson.data);
      });
  }, [company]);

  const update = async ({
    logo,
    cover,
  }: {
    logo?: string | null;
    cover?: string | null;
  }) => {
    setIsLoading(true);
    try {
      let update = await updateCompany(companyId!, {
        name: company!.name,
        address: company!.address,
        phone: company!.phone,
        logo: logo ?? company!.logo,
        cover: cover ?? company!.cover,
        gemini_model: company!.gemini_model,
        gemini_api_key: company!.gemini_api_key,
        gemini_system_instruction: company!.gemini_system_instruction,
      });

      getCompanyDetail();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpload = async (val: any) => {
    if (!val?.target) return null;
    const input = val.target as HTMLInputElement;

    setIsLoading(true);
    try {
      const resp = await uploadFile(input?.files![0], (progress) => {
        console.log(progress);
      });
      return await resp.json();
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderCompany = () => (
    <div className="w-full mx-auto ">
      {/* Banner */}
      <div className="h-[200px] relative">
        <div className=" bg-gray-100 rounded-t-lg overflow-hidden ">
          <img
            src={
              coverPicUrl != ""
                ? coverPicUrl
                : "https://images.unsplash.com/photo-1434030216411-0b793f4b4173?q=80&w=3570&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            }
            alt="Store Banner"
            className="w-full h-40 object-cover object-center cursor-pointer"
            onClick={() => fileRef2?.current?.click()}
          />
          <div className="absolute bottom-0 left-6">
            <Avatar
              size="xl"
              src={logoPicUrl}
              alt={initials(company?.name)}
              color="violet"
              circle
              className="w-24 h-24 cursor-pointer border-4 shadow-md border-white"
              onClick={() => fileRef.current?.click()}
            />
          </div>
        </div>
      </div>

      {/* Form Section */}
      <div className="bg-white  p-8 space-y-6">
        <h2 className="text-2xl font-semibold text-gray-800">
          Company Setting
        </h2>
        <p className="text-gray-500">Personalize Your Company Preferences</p>

        {/* Store Name */}
        <div>
          <label htmlFor="companyName" className="block text-gray-700">
            Name
          </label>
          <input
            id="companyName"
            name="companyName"
            type="text"
            className="mt-2 w-full border border-gray-300 rounded-lg p-2"
            value={company?.name}
            onChange={(e) => setCompany({ ...company!, name: e.target.value })}
          />
        </div>

        <div>
          <label htmlFor="phone" className="block text-gray-700">
            Telp
          </label>
          <input
            id="phone"
            name="phone"
            type="text"
            className="mt-2 w-full border border-gray-300 rounded-lg p-2"
            value={company?.phone}
            onChange={(e) => setCompany({ ...company!, phone: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="address" className="block text-gray-700">
            Address
          </label>
          <textarea
            id="address"
            name="address"
            rows={2}
            className="mt-2 w-full border border-gray-300 rounded-lg p-2"
            value={company?.address}
            onChange={(e) =>
              setCompany({ ...company!, address: e.target.value })
            }
          />
        </div>

        <h2 className="text-2xl font-semibold text-gray-800">
          Gemini Ai Assistant
        </h2>
        <p className="text-gray-500">
          Gemini is a conversational AI assistant that can help you with any
          question.
        </p>
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <label
              htmlFor="geminiApiKey"
              className="block text-gray-700 mb-2 font-[500]"
            >
              Gemini API Key
            </label>
            <Input
              id="geminiApiKey"
              name="geminiApiKey"
              type="text"
              placeholder="AIzaSyD1**********************"
              value={company?.gemini_api_key}
              onChange={(e) => setCompany({ ...company!, gemini_api_key: e })}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="geminiApiKey"
              className="block text-gray-700 mb-2 font-[500]"
            >
              Gemini Model
            </label>
            <SelectPicker
              data={geminiModels.map((e) => ({
                label: e,
                value: e,
              }))}
              value={company?.gemini_model}
              onChange={(e) => setCompany({ ...company!, gemini_model: e! })}
              block
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="geminiSystemInstruction"
              className="block text-gray-700 mb-2 font-[500]"
            >
              Gemini System Instruction
            </label>
            <Input
              id="geminiSystemInstruction"
              name="geminiSystemInstruction"
              as="textarea"
              rows={9}
              placeholder={`describe your gemini assistant system instruction
For example:
This assistant is responsible for generating code based on human instructions. 
The AI is succinct and provides just enough information to be useful: 
it will generally only generate a single function or a couple lines of code to fulfill the instruction. 
If the AI does not know how to follow the instruction, the ASSISTANT should not reply at all.
                `}
              value={company?.gemini_system_instruction}
              onChange={(e) =>
                setCompany({ ...company!, gemini_system_instruction: e })
              }
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-gray-100 text-white px-6 py-2 rounded-lg hover:bg-yellow-700"
            style={{ backgroundColor: color }}
            onClick={() => update({})}
          >
            Update Company
          </button>
        </div>

        <Divider />
        <div className="flex flex-row justify-between">
          <h2 className="text-2xl font-semibold text-gray-800">
            Rapid API plugin
          </h2>
          <button
            className="rounded-lg border bg-white flex flex-row gap-2 px-4 py-2 text-xs text-gray-600 hover:bg-gray-100 items-center"
            onClick={() => setModalRapidApi(true)}
          >
            <PiPuzzlePieceBold /> Add API
          </button>
        </div>
        {(company?.rapid_api_plugins ?? []).length === 0 ? (
          <div>No Data</div>
        ) : (
          <table className="table-auto w-full mt-4">
            <thead>
              <tr className="border-t border-b">
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2">URL</th>
                <th className="px-4 py-2">Endpoint</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {(company?.rapid_api_plugins ?? []).map((e) => (
                <tr key={e.id}>
                  <td className=" px-4 py-2">{e.name}</td>
                  <td className=" px-4 py-2">
                    <Link to={e.url} target="_blank">{e.url}</Link>
                  </td>
                  <td className=" px-4 py-2">
                    <small>{(e.endpoints??[]).map((e) => e.title).join(",")}</small>
                  </td>
                  <td className=" px-4 py-2">
                    <div className="flex flex-row gap-2">
                      <PiKeyLight
                        size={16}
                        className="cursor-pointer text-blue-400"
                        onClick={() => {
                          copyToClipboard(e.rapid_api_key);
                          successToast("API key copied to clipboard");
                        }}
                      />
                      <PiPencilLight
                        size={16}
                        className="cursor-pointer text-orange-400"
                        onClick={() => {
                          setSelectedPlugin(e.id);
                          setRapidApiKey(e.rapid_api_key);
                          setRapidApiHost(e.rapid_api_host);
                          setModalRapidApi(true);
                        }}
                      />
                      <PiTrashLight
                        size={16}
                        className="cursor-pointer text-red-400"
                        onClick={() => {
                          Swal.fire({
                            title: "Are you sure?",
                            text: "You won't be able to revert this!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, delete it!",
                          }).then(async (result) => {
                            if (result.isConfirmed) {
                              try {
                                await removeRapidPlugin(companyId!, e.id);
                                getCompanyDetail();
                                successToast("Rapid API plugin deleted");
                              } catch (error) {
                                errorToast(`${error}`);
                              }
                            }
                          });
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <input
        type="file"
        accept="image/*"
        ref={fileRef}
        className="hidden"
        onChange={async (val) => {
          try {
            setIsLoading(true);
            var resp = await handleUpload(val);
            setLogoPicUrl(resp.data.url);
            await update({ logo: resp.data.path });
            getCompanyDetail();
          } catch (error) {
            errorToast(`${error}`);
          } finally {
            setIsLoading(false);
          }
        }}
      />
      <input
        type="file"
        accept="image/*"
        ref={fileRef2}
        className="hidden"
        onChange={async (val) => {
          try {
            setIsLoading(true);
            var resp = await handleUpload(val);
            setCoverPicUrl(resp.data.url);
            await update({ cover: resp.data.path });
            getCompanyDetail();
          } catch (error) {
            errorToast(`${error}`);
          } finally {
            setIsLoading(false);
          }
        }}
      />
    </div>
  );

  return (
    <MainLayout
      onLoadProfile={setProfile}
      onLoadPermissions={(val) => {
        setPermissions(val);
      }}
    >
      <div
        className="flex flex-col flex-1 overflow-x-auto  h-full"
        style={{ backgroundColor: color }}
      >
        <div
          className="  h-full bg-white ml-4 rounded-tl-[15px]  flex flex-col"
          style={{ height: "calc(100vh - 50px)" }}
        >
          {renderCompany()}
        </div>
      </div>
      <Modal open={modalRapidApi} onClose={() => setModalRapidApi(false)}>
        <Modal.Header>Add Rapid API Plugin</Modal.Header>
        <Modal.Body>
          <InlineForm paddingY={20} label="Rapid API Plugin">
            <SelectPicker
              block
              value={selectedPlugin}
              data={plugins.map((e) => ({ label: e.name, value: e.id }))}
              onChange={(e) => setSelectedPlugin(e!)}
            />
          </InlineForm>
          <InlineForm paddingY={20} label="Rapid API Key">
            <Input
              type="text"
              placeholder="Rapid API Key"
              value={rapidApiKey}
              onChange={(e) => setRapidApiKey(e)}
            />
          </InlineForm>
          <InlineForm paddingY={20} label="Rapid API Host">
            <Input
              type="text"
              placeholder="Rapid API Host"
              value={rapidApiHost}
              onChange={(e) => setRapidApiHost(e)}
            />
          </InlineForm>
        </Modal.Body>
        <Modal.Footer>
          <Button
            loading={isLoading}
            appearance="primary"
            onClick={() => {
              setIsLoading(true);
              addRapidPlugin(company!.id, {
                rapid_api_plugin_api: selectedPlugin,
                rapid_api_key: rapidApiKey,
                rapid_api_host: rapidApiHost,
              })
                .then((res) => {
                  setModalRapidApi(false);
                  getCompanyDetail();
                })
                .catch((err) => {
                  errorToast(err);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};
export default CompanyDetail;
