import { RapidApiDataReq } from "../model/rapid_api_data";
import { customFetch } from "../utils/http";

export const createRapidApiData = async (rapidApiData: RapidApiDataReq) => {
  return await customFetch(`rapidApiData`, {
    method: "POST",
    body: JSON.stringify(rapidApiData),
  });
};

export const getRapidApiData = async (taskId: string, boardId: string) => {
  return await customFetch(`rapidApiData?task_id=${taskId}&board_id=${boardId}`);
};
export const getRapidApiBoardData = async (rapidApiEndpointId: string, boardId: string) => {
  return await customFetch(`rapidApiData?rapid_api_endpoint_id=${rapidApiEndpointId}&board_id=${boardId}`);
};
export const getRapidApiDataDetail = async (id: string) => {
  return await customFetch(`rapidApiData/${id}`);
};
export const getRapidApiGetData = async (id: string) => {
  return await customFetch(`rapidApiData/${id}/getData`);
};

export const updateRapidApiData = async (
  id: string,
  rapidApiData: RapidApiDataReq
) => {
  return await customFetch(`rapidApiData/${id}`, {
    method: "PUT",
    body: JSON.stringify(rapidApiData),
  });
};

export const deleteRapidApiData = async (id: string): Promise<void> => {
  await customFetch(`rapidApiData/${id}`, {
    method: "DELETE",
  });
};
