import React, { useContext, useEffect, useRef, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { CiViewList, CiViewTimeline } from "react-icons/ci";
import { GrMore } from "react-icons/gr";
import { IoColorPaletteOutline } from "react-icons/io5";
import {
  PiCalendarDotsLight,
  PiCheckCircle,
  PiCheckCircleFill,
  PiCopyLight,
  PiFloppyDisk,
  PiGear,
  PiKanban,
  PiTrashLight,
} from "react-icons/pi";
import { RiUserAddLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  AvatarGroup,
  Button,
  Drawer,
  Dropdown,
  Modal,
  Progress,
} from "rsuite";
import Swal from "sweetalert2";
import ModalMember from "../components/ModalMember";
import { Board, Column, Task } from "../model/board";
import { Member } from "../model/member";
import { ActivityLogReq, Project } from "../model/project";
import { User } from "../model/user";
import { BgColorContext } from "../objects/color_object";
import { CompanyContext } from "../objects/company_context";
import { LoadingContext } from "../objects/loading";
import { PermissionsContext } from "../objects/permissions_object";
import { deleteBoard, getBoard, updateBoard } from "../repositories/board";
import { createColumn, updateColumn } from "../repositories/column";
import {
  addActivityLogProject,
  addMember,
  getProject,
} from "../repositories/project";
import {
  deleteTask,
  duplicateTask,
  getTask,
  taskAddContributors,
  taskAddFiles,
  updateTask,
} from "../repositories/task";
import {
  getRandomColor,
  hexColorFromProgress,
  initials,
} from "../utils/helper";
import { errorToast, successToast, toolTip } from "../utils/helper-ui";
import { asyncLocalStorage, getProfileStorage } from "../utils/storage";
import BigCalendar from "./BigCalendar";
import KanbanBoard from "./KanbanBoard";
import ListBoard from "./ListBoard";
import ModalColor from "./ModalColor";
import SearchPeople from "./SearchPeople";
import TaskDetail from "./TaskDetail";
import TimeLine from "./TimeLine";

import { Attachment } from "@rsuite/icons";
import { BsPlusCircle } from "react-icons/bs";
import { LuKanbanSquareDashed } from "react-icons/lu";
import { FileReq } from "../model/file";
import { uploadFile } from "../repositories/file";
import ModalMoveTask from "./ModalMoveTask";
import BoardSetting from "./BoardSetting";
import { TOKEN } from "../objects/constants";
import { MdSummarize } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import BoardSummaryPage from "./BoardSummary";
import ReactQuill from "react-quill-new";
import InlineForm from "./InlineForm";
interface ProjectBoardProps {
  profile?: User;
}

const ProjectBoard: React.FC<ProjectBoardProps> = ({ profile }) => {
  const { company, setCompany } = useContext(CompanyContext);
  const fileRef = useRef<HTMLInputElement>(null);
  const { color, setColor } = useContext(BgColorContext);
  const [modalColor, setModalColor] = useState(false);
  const [modalInvite, setModalInvite] = useState(false);
  const [selectMode, setSelectMode] = useState("board");
  const { permissions, setPermissions } = useContext(PermissionsContext);
  const { boardId, projectId } = useParams();
  const [board, setBoard] = useState<Board | null>(null);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [columns, setColumns] = useState<Column[]>([]);
  const { company: activeCompany, setCompany: setActiveCompany } =
    useContext(CompanyContext);
  const nav = useNavigate();
  const [modalMoveTask, setModalMoveTask] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [tempSelectedTask, setTempSelectedTask] = useState<Task | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerSize, setDrawerSize] = useState("lg");
  const [members, setMembers] = useState<Member[]>([]);
  const [project, setProject] = useState<Project | null>(null);
  const [showModalMember, setShowModalMember] = useState(false);
  const [existingMembers, setExistingMembers] = useState<Member[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
  const [modalSettingOpen, setModalSettingOpen] = useState(false);
  const [modalBoardEditOpen, setModalBoardEditOpen] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const task_id = queryParams.get("task_id");
  const [boardTitle, setBoardTitle] = useState("");
  const [boardDesc, setBoardDesc] = useState("");
  const [token, setToken] = useState("");
  const [selectedClientTask, setSelectedClientTask] = useState<Task>();

  useEffect(() => {
    setMounted(true);
    asyncLocalStorage.getItem(TOKEN).then((res) => {
      setToken(res as string);
    });
  }, []);

  useEffect(() => {
    // console.log("profile", profile);
  }, [profile]);
  useEffect(() => {
    if (activeCompany?.is_client) {
      setSelectMode("calendar");
    }
  }, [activeCompany]);

  useEffect(() => {
    if (task_id) {
      getTask(task_id)
        .then((res) => res.json())
        .then((res) => {
          setSelectedTask(res.data);
          setTempSelectedTask(res.data);
          setDrawerOpen(true);
        })
        .catch((err) => {
          errorToast(`${err}`);
        });
    }
  }, [task_id]);

  useEffect(() => {
    if (project) {
      let objMember = new Map<string, Member>();
      for (const member of project?.members ?? []) {
        objMember.set(member.id, member);
      }
      for (const team of project?.teams ?? []) {
        for (const member of team.members) {
          objMember.set(member.id, member);
        }
      }
      setMembers(Array.from(objMember.values()));
    }
  }, [project]);

  useEffect(() => {
    if (boardId && mounted) {
      getDetail(true);
      if (projectId) {
        getProject(projectId!)
          .then((res) => res.json())
          .then((res) => {
            setProject(res.data);
          })
          .catch((err) => {
            errorToast(`${err}`);
          });
      }
      var url = `${process.env.REACT_APP_API_URL}/ws/board/${boardId}?token=${token}`;
      if (!websocket) {
        var ws = new WebSocket(url, []);

        setWebsocket(ws);

        ws.onopen = (msg) => {
          ws.send(
            JSON.stringify({
              type: "GREETING",
              message: "HALLO GUYS",
            })
          );
        };
        ws.onmessage = async (msg: any) => {
          let profile = await getProfileStorage();
          // console.log("profile", profile);
          const data = JSON.parse(msg.data);
          // console.log(data.action)
          if (
            (data.action == "MOVE" && profile?.id !== data.userId) ||
            data.targetType == "TASK" ||
            data.action == "ADD_TASK" ||
            data.action == "EDIT_DESC" ||
            data.action == "CREATE_COLUMN" ||
            data.action == "MOVE_TASK" ||
            data.action == "ADD_SUB_TASK" ||
            data.action == "MOVE_BOARD_TASK" ||
            data.action == "UPDATE_PRIORITY" ||
            data.action == "ADD_CUSTOM_FIELDS" ||
            data.action == "UPDATE_CUSTOM_FIELDS" ||
            data.action == "DELETE_COLUMN"
          ) {
            const url = window.location.href;
            const pathname = window.location.pathname;
            // console.log(pathname.split("/").pop());
            // console.log("RECEIVE MESSAGE WS", data, profile?.id,data.userId, (data.action == "MOVE" && profile?.id !== data.userId) && "MASUK" );
            getDetail(false, pathname.split("/").pop());
          }
        };
      }
    }
  }, [boardId, projectId, mounted, profile]);

  const getDetail = async (loading: boolean, id?: string) => {
    if (id) {
      console.log("GET BOARD DETAIL", id);
    }
    try {
      if (loading) {
        setIsLoading(true);
      }
      const res = await getBoard(id ?? boardId!);
      const resJson = await res.json();
      setBoard(resJson.data);
    } catch (err) {
      errorToast(`${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = `${project?.title} > ${board?.title} | ${
      board?.description ?? "AGENSIA"
    }`;
  }, [project, board]);

  useEffect(() => {
    if (board) {
      // console.log("UPDATE COLUMN");
      if (selectedTask) {
        let newTask = board.columns
          .find((e) => e.id == selectedTask.column_id)!
          .tasks.find((t) => t.id == selectedTask.id)!;
        setSelectedTask(newTask);
        setTempSelectedTask(newTask);
        let newColumn = board.columns.find(
          (e) => e.id == selectedTask!.column_id
        )!;

        // console.log("board", board);
        // console.log(
        //   "columns",
        //   board.columns.map((e) => {
        //     if (e.id == newColumn.id) {
        //       return newColumn;
        //     }
        //     return e;
        //   })
        // );
        setColumns(
          board.columns.map((e) => {
            if (e.id == newColumn.id) {
              return newColumn;
            }
            return e;
          })
        );
      }
      setColumns(board.columns);
      setColor(board.color ?? "#ede9fe");
      setBoardTitle(board?.title);
      setBoardDesc(board?.description);
    }
  }, [board]);
  //   const [data, setData] = useState(initialData);

  // Function to handle task movement between columns
  const onDragEnd = (result: DropResult) => {
    const { source, destination, type } = result;

    if (!destination) return;
    if (type === "CATEGORY") {
      setColumns((prevColumns) => {
        const newColumns = [...prevColumns];
        const [movedColumn] = newColumns.splice(source.index, 1);
        newColumns.splice(destination.index, 0, movedColumn);

        for (let index = 0; index < newColumns.length; index++) {
          const column = newColumns[index];
          let data = {
            title: column.title,
            board_id: column.board_id,
            color: column.color,
            position: index + 1,
          };
          if (movedColumn.id == column.id) {
            onUpdateActivity(
              {
                company_id: activeCompany!.id,
                project_id: projectId!,
                board_id: boardId!,
                activity_type: "MOVE",
                context_type: "COLUMN",
                pre_condition: JSON.stringify({
                  title: movedColumn.title,
                  board_id: movedColumn.board_id,
                  color: movedColumn.color,
                  position: movedColumn.position,
                }),
                data: JSON.stringify(data),
              },
              true
            );
          }
          updateColumn(column.id, data).then(() => {
            if (websocket) {
              websocket.send(
                JSON.stringify({
                  type: "UPDATE",
                  action: "MOVE",
                  targetId: board?.id,
                  targetType: "BOARD",
                  userId: profile?.id,
                })
              );
            }
          });
        }
        for (const column of newColumns) {
        }
        return newColumns;
      });
    }
    if (type === "ITEM") {
      setColumns((prevColumns) => {
        const newColumns = [...prevColumns];
        const sourceColIndex = newColumns.findIndex(
          (col) => col.id === source.droppableId
        );
        const destColIndex = newColumns.findIndex(
          (col) => col.id === destination.droppableId
        );

        const sourceCol = newColumns[sourceColIndex];
        const destCol = newColumns[destColIndex];

        let sourceTasks = [...sourceCol.tasks];
        let destTasks =
          sourceColIndex === destColIndex ? sourceTasks : [...destCol.tasks];

        const [removed] = sourceTasks.splice(source.index, 1);
        removed.column_id = columns.find(
          (e) => e.id == destination.droppableId
        )?.id!;

        destTasks.splice(destination.index, 0, removed);

        newColumns[sourceColIndex] = {
          ...sourceCol,
          tasks: sourceTasks,
        };

        if (sourceColIndex !== destColIndex) {
          newColumns[destColIndex] = {
            ...destCol,
            tasks: destTasks,
          };
        }

        // console.log(destTasks.map((e) => [e.title, e.position]));

        const updateBulkTask = async () => {
          for (let index = 0; index < destTasks.length; index++) {
            const task = destTasks[index];
            await updateTask(task.id, {
              title: task.title,
              priority: task.priority,
              type: task.type,
              progress: task.progress,
              dependencies: task.dependencies,
              contributors: task.contributors,
              column_id: task.column_id,
              position: index + 1,
            });
            if (sourceCol.id != destCol.id && removed.id == task.id) {
              onUpdateActivity(
                {
                  company_id: activeCompany!.id,
                  project_id: projectId!,
                  board_id: boardId!,
                  activity_type: "MOVE_TASK",
                  context_type: "COLUMN",
                  task_id: task.id,
                  pre_condition: JSON.stringify({
                    board_id: sourceCol.id,
                    title: sourceCol.title,
                    color: sourceCol.color,
                    position: sourceCol.position,
                  }),
                  data: JSON.stringify({
                    board_id: destCol.id,
                    title: destCol.title,
                    color: destCol.color,
                    position: destCol.position,
                  }),
                },
                false
              );
            }
          }
          if (websocket) {
            websocket.send(
              JSON.stringify({
                type: "UPDATE",
                action: "MOVE_TASK",
                targetId: board?.project_id,
                targetType: "BOARD",
                userId: profile?.id,
              })
            );
          }
        };

        updateBulkTask();

        return newColumns;
      });
    }
  };

  const onUpdateActivity = async (
    act: ActivityLogReq,
    skipUpdateWs?: boolean | null
  ) => {
    return await addActivityLogProject(act).then(() => {
      if (websocket && !skipUpdateWs) {
        let req = {};
        if (act.context_type == "TASK") {
          req = {
            type: "UPDATE",
            action: act.activity_type,
            targetId: selectedTask?.id,
            targetType: act.context_type,
            userId: profile?.id,
          };
          websocket.send(JSON.stringify(req!));
        }
      }
    });
  };

  const onColumnChange = (val: Column) => {
    setColumns(
      columns.map((e) => {
        if (e.id == val.id) {
          return val;
        }
        return e;
      })
    );

    updateColumn(val.id, {
      title: val.title,
      board_id: boardId!,
      color: val.color,
      position: val.position,
    }).then(() => {
      if (websocket) {
        websocket.send(
          JSON.stringify({
            type: "UPDATE",
            action: "EDIT_COLUMN",
            targetId: board?.id,
            targetType: "BOARD",
            userId: profile?.id,
          })
        );
      }
    });
  };

  const onTaskChange = (val: Task, action?: string | null) => {
    if (!val) return;
    setColumns([
      ...columns.map((e) => {
        if (e.id == val.column_id) {
          return {
            ...e,
            tasks: e.tasks.map((t) => {
              if (t.id == val.id) {
                return val;
              }
              return t;
            }),
          };
        }
        return e;
      }),
    ]);
    if (activeCompany?.is_client) {
      return;
    }
    updateTask(val.id, {
      title: val.title,
      priority: val.priority,
      type: val.type,
      progress: val.progress,
      dependencies: val.dependencies,
      contributors: val.contributors,
      column_id: val.column_id,
      position: val.position,
      date: val.date,
      due_date: val.due_date,
      description: JSON.stringify(val.description),
    }).then(() => {
      if (websocket) {
        // console.log("SEND")
        websocket.send(
          JSON.stringify({
            type: "UPDATE",
            action: action ?? "EDIT_TASK",
            targetId: board?.id,
            targetType: "BOARD",
            userId: profile?.id,
          })
        );
      }
    });
  };
  const onSelectTask = (val: Task) => {
    // console.log("SELECT TASK", val);
    setSelectedTask(val);
    setDrawerOpen(true);
  };
  const onClickContributor = (task: Task, val: Member[]) => {
    setSelectedTask(task);
    setExistingMembers(val);
    // console.log(val)
    setShowModalMember(true);
  };
  return (
    <div className="flex flex-col">
      <div>
        <div className="pt-4 px-4 flex flex-row justify-between items-center">
          <div className="flex flex-row items-center  flex-1">
            <div className="flex flex-col ">
              <h3 className="text-gray-400 text-sm">{project?.title}</h3>
              <input
                type="text"
                className=" text-2xl font-[500] focus:ring-0 focus:outline-none focus:ring-gray-200"
                value={boardTitle}
                onChange={(val) => {
                  setBoardTitle(val.target.value);
                }}
                onBlur={() => {
                  updateBoard(boardId!, {
                    title: boardTitle,
                  });
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    updateBoard(boardId!, {
                      title: boardTitle,
                    });
                  }
                }}
              />
              <textarea
                className="text-xs focus:ring-0 focus:outline-none focus:ring-gray-200 resize-none w-[400px] text-gray-600"
                placeholder="Add Description"
                rows={2}
                value={boardDesc}
                onChange={(val) => {
                  setBoardDesc(val.target.value);
                }}
                onBlur={() => {
                  updateBoard(boardId!, {
                    description: boardDesc,
                  });
                }}
              />
            </div>
          </div>
          {!activeCompany?.is_client && (
            <div className="flex flex-row items-center gap-2">
              <AvatarGroup stack>
                {members
                  .filter((member, i) => i < 4)
                  .map((member) => (
                    <Avatar
                      size="xs"
                      bordered
                      circle
                      key={member.user.full_name}
                      src={member.user.picture_url}
                      alt={initials(member.user.full_name)}
                      color="violet"
                    />
                  ))}
                {members.length - 4 > 0 && (
                  <Avatar
                    size="xs"
                    bordered
                    circle
                    style={{ background: "#111" }}
                  >
                    +{members.length - 4}
                  </Avatar>
                )}
              </AvatarGroup>
              <button
                className="rounded-lg border bg-white flex flex-row gap-2 px-4 py-2 text-xs text-gray-600 hover:bg-gray-100 items-center"
                onClick={() => setModalInvite(true)}
              >
                <RiUserAddLine /> Invite User
              </button>

              <Dropdown
                className="custom-menu"
                title={<GrMore />}
                noCaret
                menuStyle={{ width: 240 }}
                placement="bottomEnd"
                trigger={"click"}
              >
                {/* <Dropdown.Item
                  shortcut={<BsGear />}
                  onClick={() => setModalSettingOpen(true)}
                >
                  Setting
                </Dropdown.Item>
                <Dropdown.Item
                  shortcut={<HiPencilSquare />}
                  onClick={() => setModalBoardEditOpen(true)}
                >
                  Edit
                </Dropdown.Item> */}

                <Dropdown.Item
                  shortcut={<IoColorPaletteOutline />}
                  onClick={() => setModalColor(true)}
                >
                  Color
                </Dropdown.Item>
                <Dropdown.Separator />
                <Dropdown.Item
                  shortcut={<PiTrashLight />}
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure?",
                      text: "You won't be able to revert this!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, delete it!",
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        try {
                          setIsLoading(true);
                          await deleteBoard(board!.id);
                          nav(`/project/${projectId}`);
                        } catch (err) {
                          errorToast(`${err}`);
                        } finally {
                          setIsLoading(false);
                        }
                      }
                    });
                  }}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown>
            </div>
          )}
        </div>
        <div className="flex flex-row px-4 ">
          <div
            className="flex flex-row gap-1 justify-center items-center px-8 cursor-pointer  border-b-2 py-2"
            style={{
              borderBottomColor: selectMode == "summary" ? color : undefined,
            }}
            onClick={() => setSelectMode("summary")}
          >
            <TbReportAnalytics />
            <span>Summary</span>
          </div>
          {!activeCompany?.is_client && (
            <div
              className="flex flex-row gap-1 justify-center items-center px-8 cursor-pointer  border-b-2 py-2 relative"
              style={{
                borderBottomColor: selectMode == "board" ? color : undefined,
              }}
              onClick={() => setSelectMode("board")}
            >
              <PiKanban />
              <span>Board</span>
              {selectMode == "board" && (
                <div
                  className=" absolute right-0 group/item"
                  onClick={() => {
                    createColumn({
                      title: "New Column",
                      board_id: boardId!,
                      color: getRandomColor(),
                      position: (board?.columns ?? []).length + 1,
                    }).then(() => {
                      if (websocket)
                        websocket.send(
                          JSON.stringify({
                            type: "UPDATE",
                            action: "CREATE_COLUMN",
                            targetId: board?.id,
                            targetType: "BOARD",
                            userId: profile?.id,
                          })
                        );
                    });
                  }}
                >
                  <BsPlusCircle
                    className="group/edit invisible group-hover/item:visible"
                    style={{
                      color: selectMode == "board" ? color : undefined,
                    }}
                  />
                </div>
              )}
            </div>
          )}
          {!activeCompany?.is_client && (
            <div
              className="flex flex-row gap-1 justify-center items-center px-8 cursor-pointer  border-b-2 py-2"
              style={{
                borderBottomColor: selectMode == "list" ? color : undefined,
              }}
              onClick={() => setSelectMode("list")}
            >
              <CiViewList />
              <span>List</span>
            </div>
          )}

          <div
            className="flex flex-row gap-1 justify-center items-center px-8 cursor-pointer  border-b-2 py-2"
            style={{
              borderBottomColor: selectMode == "calendar" ? color : undefined,
            }}
            onClick={() => setSelectMode("calendar")}
          >
            <PiCalendarDotsLight />
            <span>Calendar</span>
          </div>

          <div
            className="flex flex-row gap-1 justify-center items-center px-8 cursor-pointer  border-b-2 py-2"
            style={{
              borderBottomColor: selectMode == "timeline" ? color : undefined,
            }}
            onClick={() => setSelectMode("timeline")}
          >
            <CiViewTimeline />
            <span>Timeline</span>
          </div>
          {permissions.includes("update_board") && (
            <div
              className="flex flex-row gap-1 justify-center items-center px-8 cursor-pointer  border-b-2 py-2"
              style={{
                borderBottomColor: selectMode == "setting" ? color : undefined,
              }}
              onClick={() => setSelectMode("setting")}
            >
              <PiGear />
              <span>Setting</span>
            </div>
          )}
          {/* <div
            className="flex flex-row gap-1 justify-center items-center px-8 cursor-pointer  border-b-2 py-2"
            style={{
              borderBottomColor: selectMode == "dropbox" ? color : undefined,
            }}
            onClick={() => setSelectMode("dropbox")}
          >
            <PiDropboxLogoLight />
            <span>Drop Box</span>
          </div> */}
        </div>
        <div className="border-b-[0.5px] border-b-gray-300 -m-t-3"></div>
      </div>
      <div className="flex-1 bg-white overflow-y-auto">
        {selectMode == "summary" && (
          <BoardSummaryPage
            board={board}
            profile={profile}
            onClickTask={(val) => {
              getTask(val.id)
                .then((res) => res.json())
                .then((res) => {
                  setSelectedTask(res.data);
                  setDrawerOpen(true);
                });
            }}
          />
        )}
        {selectMode == "board" && (
          <KanbanBoard
            project={project}
            data={columns}
            onDragEnd={onDragEnd}
            onColumnsChange={(val) => setColumns(val)}
            onColumnChange={onColumnChange}
            onTaskChange={onTaskChange}
            onSelectTask={onSelectTask}
            ws={websocket}
            profile={profile}
            onClickContributor={onClickContributor}
            onUpdateActivity={onUpdateActivity}
          />
        )}
        {selectMode == "list" && (
          <ListBoard
            project={project}
            data={columns}
            onDragEnd={onDragEnd}
            onColumnsChange={(val) => setColumns(val)}
            onColumnChange={onColumnChange}
            onTaskChange={onTaskChange}
            onSelectTask={onSelectTask}
            ws={websocket}
            profile={profile}
            onClickContributor={onClickContributor}
            onUpdateActivity={onUpdateActivity}
          />
        )}
        {selectMode == "calendar" && (
          <BigCalendar
            project={project}
            data={columns}
            onColumnChange={onColumnChange}
            onTaskChange={onTaskChange}
            onSelectTask={onSelectTask}
            onUpdateActivity={onUpdateActivity}
          />
        )}
        {selectMode == "timeline" && (
          <TimeLine
            project={project}
            data={columns}
            onColumnChange={onColumnChange}
            onTaskChange={onTaskChange}
            onSelectTask={onSelectTask}
            onUpdateActivity={onUpdateActivity}
          />
        )}
        {selectMode == "setting" && board && (
          <BoardSetting
            board={board}
            onSave={(val, show) => {
              updateBoard(val.id, {
                board_notification_enabled: val.board_notification_enabled,
                board_email_notification: val.board_email_notification,
                board_push_notification: val.board_push_notification,
                board_sms_notification: val.board_sms_notification,
                comment_notification_enabled: val.comment_notification_enabled,
                comment_email_notification: val.comment_email_notification,
                comment_push_notification: val.comment_push_notification,
                comment_sms_notification: val.comment_sms_notification,
                assign_task_notification_enabled:
                  val.assign_task_notification_enabled,
                assign_task_email_notification:
                  val.assign_task_email_notification,
                assign_task_push_notification:
                  val.assign_task_push_notification,
                assign_task_sms_notification: val.assign_task_sms_notification,
                change_task_status_notification_enabled:
                  val.change_task_status_notification_enabled,
                change_task_status_email_notification:
                  val.change_task_status_email_notification,
                change_task_status_push_notification:
                  val.change_task_status_push_notification,
                change_task_status_sms_notification:
                  val.change_task_status_sms_notification,
                custom_fields: JSON.stringify(val.custom_fields),
                rapid_api_plugin_summaries: JSON.stringify(val.rapid_api_plugin_summaries),
              }).then(() => {
                getDetail(false);
                if (show) successToast("Setting updated");
              });
            }}
          />
        )}
      </div>
      <input
        type="file"
        ref={fileRef}
        multiple
        className="hidden"
        onChange={async (val) => {
          if (val.target.files && selectedTask) {
            try {
              setIsLoading(true);
              let _files: FileReq[] = [];
              for (let index = 0; index < val.target.files.length; index++) {
                const file = val.target.files[index];
                let resp = await uploadFile(file, (progress) => {});
                let respJson = await resp.json();
                _files.push({
                  ...respJson.data,
                  filename: file.name,
                  caption: file.name,
                });
              }

              await taskAddFiles(selectedTask!.id, _files).then((v) => {
                for (const f of _files) {
                  onUpdateActivity({
                    company_id: activeCompany!.id,
                    project_id: projectId!,
                    board_id: boardId!,
                    task_id: selectedTask!.id,
                    activity_type: "UPLOAD_IMAGE",
                    context_type: "TASK",
                    pre_condition: "{}",
                    data: JSON.stringify(f),
                  });
                }
              });
              if (websocket) {
                websocket.send(
                  JSON.stringify({
                    type: "UPDATE",
                    action: "ADD_FILES",
                    targetId: selectedTask?.id,
                    targetType: "TASK",
                    userId: profile?.id,
                  })
                );
              }
            } catch (error) {
              console.log(error);
              errorToast(`${error}`);
            } finally {
              setIsLoading(false);
            }
          }
        }}
      />
      <ModalColor
        open={modalColor}
        onClose={() => setModalColor(false)}
        color={color}
        onChange={(val) => {
          setColor(val.hex);
          updateBoard(boardId!, {
            color: val.hex,
          });
        }}
      />

      <Modal open={modalInvite} onClose={() => setModalInvite(false)}>
        <Modal.Header>
          <Modal.Title>Invite Member To Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchPeople
            existingsMembers={members}
            invitationType="MEMBER"
            onSuccess={() => setModalInvite(false)}
            onChangeMembers={(val) => {
              // console.log(val);
              setSelectedMembers(val);
            }}
          />
        </Modal.Body>
        {selectedMembers.length > 0 && (
          <Modal.Footer>
            <Button
              appearance="primary"
              onClick={() => {
                if (selectedMembers.length > 0) {
                  setModalInvite(false);
                  setIsLoading(true);
                  addMember(
                    project?.id!,
                    selectedMembers.map((m) => m.id)
                  )
                    .then((res) => res.json())
                    .then((resp) => {
                      setMembers([...selectedMembers]);
                    })
                    .catch((error) => errorToast(error))
                    .finally(() => setIsLoading(false));
                }
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      <Drawer
        size={drawerSize}
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
          onTaskChange(tempSelectedTask!);
          setSelectedTask(null);
        }}
      >
        <Drawer.Header>
          {/* <Drawer.Title>{selectedTask?.title}</Drawer.Title> */}

          <Drawer.Actions className="flex flex-row space-x-2">
            {!company?.is_client && (
              <Button
                onClick={() => {
                  onTaskChange(tempSelectedTask!);
                }}
              >
                <PiFloppyDisk className=" text-gray-500 hover:text-gray-800" />
              </Button>
            )}

            {!company?.is_client &&
              toolTip(
                !(selectedTask?.is_completed ?? false)
                  ? "Complete Task"
                  : "Task Completed",
                <Button
                  onClick={() => {
                    if (!selectedTask) return;
                    setSelectedTask({
                      ...selectedTask!,
                      progress: 100,
                      is_completed: true,
                    });
                    onTaskChange({
                      ...selectedTask!,
                      progress: 100,
                      is_completed: true,
                    });
                    onUpdateActivity({
                      company_id: activeCompany!.id,
                      project_id: project!.id,
                      board_id: boardId!,
                      task_id: selectedTask!.id,
                      activity_type: "UPDATE_PROGRESS",
                      context_type: "TASK",
                      pre_condition: JSON.stringify({
                        progress: selectedTask?.progress,
                      }),
                      data: JSON.stringify({
                        progress: 100,
                      }),
                    });
                  }}
                >
                  {!(selectedTask?.is_completed ?? false) ? (
                    <PiCheckCircle className=" hover:text-green-500" />
                  ) : (
                    <PiCheckCircleFill className=" text-green-500" />
                  )}
                </Button>,
                "leftStart"
              )}
            {!company?.is_client &&
              toolTip(
                "Attach File",
                <Button
                  onClick={() => {
                    fileRef?.current?.click();
                  }}
                >
                  <Attachment />
                </Button>,
                "leftStart"
              )}

            {!company?.is_client &&
              toolTip(
                "Move Task",
                <Button onClick={() => setModalMoveTask(true)}>
                  <LuKanbanSquareDashed />
                </Button>,
                "leftStart"
              )}
            {!company?.is_client &&
              toolTip(
                "Duplicate Task",
                <Button
                  onClick={async () => {
                    try {
                      if (selectedTask) {
                        setIsLoading(true);
                        const res = await duplicateTask(selectedTask!.id);
                        const resJson = await res.json();
                        await getDetail(false);
                      }
                    } catch (err) {
                      errorToast(`${err}`);
                    } finally {
                      setIsLoading(false);
                    }
                  }}
                >
                  <PiCopyLight />
                </Button>,
                "leftStart"
              )}
            {toolTip(
              "Full Screen",
              <Button
                title="Full Screen"
                onClick={() => {
                  if (drawerSize == "lg") {
                    setDrawerSize("full");
                  } else {
                    setDrawerSize("lg");
                  }
                }}
              >
                {drawerSize != "lg" ? (
                  <AiOutlineFullscreenExit />
                ) : (
                  <AiOutlineFullscreen />
                )}
              </Button>,
              "leftStart"
            )}
            {!company?.is_client &&
              toolTip(
                "Delete Task",
                <Button
                  title="Delete"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure?",
                      text: "You won't be able to revert this!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, delete it!",
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        try {
                          setIsLoading(true);
                          await deleteTask(selectedTask!.id);
                          await getDetail(false);
                          setDrawerOpen(false);
                          onTaskChange(tempSelectedTask!);
                          setSelectedTask(null);
                        } catch (err) {
                          errorToast(`${err}`);
                        } finally {
                          setIsLoading(false);
                        }
                      }
                    });
                  }}
                >
                  <PiTrashLight />
                </Button>,
                "leftStart"
              )}
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body className="custom-drawer">
          <TaskDetail
            board={board}
            onUpdateActivity={onUpdateActivity}
            ws={websocket}
            profile={profile}
            onClickContributor={onClickContributor}
            task={selectedTask!}
            onTaskChange={(val) => {
              setSelectedTask(val);
              // console.log("TASK", val.date, val.due_date);
            }}
            onForceTaskChange={async (val) => {
              onTaskChange(val);
            }}
            onTempTaskChange={(val) => {
              setTempSelectedTask(val);
              // console.log("TEMP TASK", val.description);
            }}
            onCommentSucced={async () => {
              const res = await getBoard(boardId!);
              const resJson = await res.json();
              setBoard(resJson.data);

              // let newBoard: Board = resJson.data;
            }}
          />
        </Drawer.Body>
      </Drawer>
      <ModalMember
        title="Choose Member"
        onClose={() => setShowModalMember(false)}
        open={showModalMember}
        members={members}
        existingMembers={existingMembers}
        onSave={(val) => {
          setColumns([
            ...columns.map((e) => {
              if (e.id == selectedTask?.column_id) {
                return {
                  ...e,
                  tasks: e.tasks.map((t) => {
                    if (t.id == selectedTask?.id) {
                      selectedTask.contributors = val;
                      return selectedTask;
                    }
                    return t;
                  }),
                };
              }
              return e;
            }),
          ]);

          taskAddContributors(
            selectedTask!.id,
            val.map((e) => e.id)
          ).then(() => {
            onUpdateActivity(
              {
                company_id: activeCompany!.id,
                project_id: projectId!,
                board_id: boardId!,
                activity_type: "EDIT_CONTRIBUTOR",
                context_type: "TASK",
                task_id: selectedTask?.id,
                pre_condition: "{}",
                data: JSON.stringify(
                  val.map((e) => ({ id: e.id, user: e.user }))
                ),
              },
              true
            );
            if (websocket) {
              websocket.send(
                JSON.stringify({
                  type: "UPDATE",
                  action: "EDIT_TASK",
                  targetId: board?.id,
                  targetType: "BOARD",
                  userId: profile?.id,
                })
              );
            }
            setSelectedTask({
              ...selectedTask!,
              contributors: val,
            });
            setExistingMembers([]);
            setShowModalMember(false);
          });
        }}
      />
      <Modal
        size={"1024px"}
        open={modalSettingOpen}
        onClose={() => setModalSettingOpen(false)}
      >
        <Modal.Header>Settings</Modal.Header>
        <Modal.Body></Modal.Body>
      </Modal>
      {setModalMoveTask && (
        <ModalMoveTask
          task={selectedTask}
          open={modalMoveTask}
          onClose={() => setModalMoveTask(false)}
          onTaskChange={(val) => {
            updateTask(val.id, {
              title: val.title,
              priority: val.priority,
              type: val.type,
              progress: val.progress,
              dependencies: val.dependencies,
              contributors: val.contributors,
              column_id: val.column_id,
              position: val.position,
              date: val.date,
              due_date: val.due_date,
              description: JSON.stringify(val.description),
            }).then(() => {
              getDetail(false);
              setDrawerOpen(false);
              setSelectedTask(null);
              if (websocket) {
                websocket.send(
                  JSON.stringify({
                    type: "UPDATE",
                    action: "MOVE_TASK",
                    targetId: board?.id,
                    targetType: "BOARD",
                    userId: profile?.id,
                  })
                );
              }
            });

            // onTaskChange(val, "MOVE_TASK");
            setModalMoveTask(false);
          }}
        />
      )}
    </div>
  );
};

export default ProjectBoard;
